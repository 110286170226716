import React, { Component } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./app-pagination.css";

class AppPagination extends Component {
  state = {
    activePage: 1,
    pages: 0,
    start: 0,
    end: 10,
    showingFrom: 1,
    showingTo: 50
  };

  componentDidMount() {
    const { records } = this.props;
    const pages = Math.ceil(records / 50);
    this.setState({ pages });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activePage !== prevState.activePage) {
      this.props.onPaginationItemClick(this.state.activePage);
    }
  }

  handlePaginationItemClick = (e, page) => {
    const { activePage } = this.state;
    const { onPaginationItemClick } = this.props;
    e.preventDefault();
    if (page !== activePage) {
      this.setState({
        activePage: page,
        showingFrom: page === 1 ? 1 : 50 * (page - 1) + 1,
        showingTo:
          page === 1
            ? 50
            : 50 * (page - 1) + 50 < this.props.records
            ? 50 * (page - 1) + 50
            : this.props.records
      });
      onPaginationItemClick(page);
    }
  };

  hanldePageNumbers = (start, end) => {
    const { activePage, pages } = this.state;

    const paginationItems = [];
    for (let page = 1; page <= pages; page++) {
      paginationItems.push(
        <PaginationItem active={activePage === page}>
          <PaginationLink
            href="#"
            onClick={e => this.handlePaginationItemClick(e, page)}
          >
            {page}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return paginationItems.slice(start, end);
  };

  handleNextClick = e => {
    const { activePage, start, end, showingFrom, showingTo } = this.state;
    e.preventDefault();
    this.setState({
      start: activePage >= 10 ? start + 1 : 0,
      end: activePage >= 10 ? end + 1 : 10,
      activePage: activePage + 1,
      showingFrom: showingFrom + 50,
      showingTo:
        showingTo + 50 < this.props.records
          ? showingTo + 50
          : this.props.records
    });
  };

  handlePreviousClick = e => {
    const {
      activePage,
      start,
      end,
      showingFrom,
      showingTo,
      pages
    } = this.state;
    e.preventDefault();
    this.setState({
      start: activePage > 10 ? start - 1 : 0,
      end: activePage > 10 ? end - 1 : 10,
      activePage: activePage - 1,
      showingFrom: showingFrom - 50,
      showingTo:
        pages * 50 < this.props.records ? showingTo - 50 : showingFrom - 1
    });
  };

  render() {
    const {
      activePage,
      pages,
      start,
      end,
      showingFrom,
      showingTo
    } = this.state;
    return (
      <div className="app-pagination">
        <div className="pagination-status">
          Showing {showingFrom} - {showingTo} of {this.props.records} schools or
          colleges
        </div>
        <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={activePage === 1}>
            <PaginationLink
              previous
              href="#"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  activePage: 1,
                  start: 0,
                  end: 10,
                  showingFrom: 1,
                  showingTo: 50
                });
              }}
            />
          </PaginationItem>
          <PaginationItem disabled={activePage === 1}>
            <PaginationLink href="#" onClick={this.handlePreviousClick}>
              ‹
            </PaginationLink>
          </PaginationItem>
          {this.hanldePageNumbers(start, end)}
          <PaginationItem disabled={activePage === pages}>
            <PaginationLink href="#" onClick={this.handleNextClick}>
              ›
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={activePage === pages}>
            <PaginationLink
              next
              href="#"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  activePage: pages,
                  start: pages - 10,
                  end: pages,
                  showingFrom: 50 * pages - 50 + 1,
                  showingTo: this.props.records
                });
              }}
            />
          </PaginationItem>
        </Pagination>
      </div>
    );
  }
}

export default AppPagination;
