import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import "./input-field.css";
class InputField extends Component {
  state = {
    showPassword: false
  };
  toggleViewPassword = e => {
    e.preventDefault();
    this.focusRef._reactInternalFiber.child.stateNode.focus();
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const {
      label,
      name,
      type,
      placeholder,
      value,
      onChange,
      invalid,
      errorMessage,
      id,
      className = "",
      viewPassword,
      defaultValue,
      hidden,
      disabled
    } = this.props;
    const { showPassword } = this.state;
    return (
      <FormGroup
        className={`input-form-group ${viewPassword ? "password-group" : ""}`}
      >
        {label && <Label for={id}>{label}</Label>}
        <div className="input-wrapper">
          <Input
            ref={focusRef => (this.focusRef = focusRef)}
            className={className}
            type={showPassword ? "text" : type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            invalid={invalid}
            id={id}
            autoComplete="new-password"
            defaultValue={defaultValue}
            hidden={hidden}
            disabled={disabled}
          />
          {viewPassword && (
            <span
              onClick={this.toggleViewPassword}
              className={`show-password ${showPassword ? "" : "hide-password"}`}
            />
          )}
          <FormFeedback>{errorMessage}</FormFeedback>
        </div>
      </FormGroup>
    );
  }
}

InputField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  hidden: PropTypes.bool
};

export { InputField };
