import { FormGroup, Label } from "reactstrap";
import React from "react";
import {
  Top,
  Accessories,
  HairColors,
  FacialHairTypes,
  FacialHairColors,
  ClotheTypes,
  EyeTypes,
  EyebrowTypes,
  MouthTypes,
  SkinTypes,
  ClotheColors
} from "./Options";
import "./avatarform.css";

const Select = ({ name, id, onChange, className, label, value, children }) => {
  return (
    <div className="select-cnt ">
      <Label for={name}>{label}</Label>
      <select
        type="select"
        name={name}
        id={id}
        onChange={onChange}
        className={className}
        value={value}
      >
        {children}
      </select>
    </div>
  );
};

const AvatarForm = props => (
  <div>
    <FormGroup>
      <Label for="topType" className="avatar-form-label">
        Top
      </Label>
      <Select
        type="select"
        name="topType"
        id="topType"
        className="avatar-select-field"
        value={props.topType}
        onChange={props.onChange}
      >
        {Top.map(item => (
          <option key={item.id} value={item.name}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormGroup>

    {props.renderAccessories && (
      <FormGroup className="nested-group">
        <Label for="accessoriesType" className="avatar-form-label">
          ↳ 👓 Accessories
        </Label>
        <Select
          type="select"
          name="accessoriesType"
          id="accessoriesType"
          value={props.accessoriesType}
          onChange={props.onChange}
        >
          {Accessories.map(item => (
            <option key={item.id} value={item.name}>
              {item.label}
            </option>
          ))}
        </Select>
      </FormGroup>
    )}

    {props.renderHairColor && (
      <FormGroup className="nested-group">
        <Label for="hairColor" className="avatar-form-label">
          ↳ 💈 Hair Color
        </Label>
        <Select
          type="select"
          name="hairColor"
          id="hairColor"
          value={props.hairColor}
          onChange={props.onChange}
        >
          {HairColors.map(item => (
            <option key={item.id} value={item.name}>
              {item.label}
            </option>
          ))}
        </Select>
      </FormGroup>
    )}

    <FormGroup>
      <Label for="facialHairType" className="avatar-form-label">
        Facial Hair
      </Label>
      <Select
        type="select"
        name="facialHairType"
        id="facialHairType"
        value={props.facialHairType}
        onChange={props.onChange}
      >
        {FacialHairTypes.map(item => (
          <option key={item.id} value={item.name}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormGroup>

    {props.renderFacialHairColour && (
      <FormGroup className="nested-group">
        <Label for="facialHairColor" className="avatar-form-label">
          ↳ ✂️ Facial Hair Color
        </Label>
        <Select
          type="select"
          name="facialHairColor"
          id="facialHairColor"
          value={props.facialHairColor}
          onChange={props.onChange}
        >
          {FacialHairColors.map(item => (
            <option key={item.id} value={item.name}>
              {item.label}
            </option>
          ))}
        </Select>
      </FormGroup>
    )}

    <FormGroup>
      <Label for="clotheType" className="avatar-form-label">
        Clothes
      </Label>
      <Select
        type="select"
        name="clotheType"
        id="clotheType"
        value={props.clotheType}
        onChange={props.onChange}
      >
        {ClotheTypes.map(item => (
          <option key={item.id} value={item.name}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormGroup>

    {props.renderClothesFabric && (
      <FormGroup className="nested-group">
        <Label for="clotheColor" className="avatar-form-label">
          ↳ Color Fabric
        </Label>
        <Select
          type="select"
          name="clotheColor"
          id="clotheColor"
          value={props.clotheColor}
          onChange={props.onChange}
        >
          {ClotheColors.map(item => (
            <option key={item.id} value={item.name}>
              {item.label}
            </option>
          ))}
        </Select>
      </FormGroup>
    )}

    <FormGroup>
      <Label for="eyeType" className="avatar-form-label">
        Eyes
      </Label>
      <Select
        type="select"
        name="eyeType"
        id="eyeType"
        value={props.eyeType}
        onChange={props.onChange}
      >
        {EyeTypes.map(item => (
          <option key={item.id} value={item.name}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormGroup>

    <FormGroup>
      <Label for="eyebrowType" className="avatar-form-label">
        Eyebrows
      </Label>
      <Select
        type="select"
        name="eyebrowType"
        id="eyebrowType"
        value={props.eyebrowType}
        onChange={props.onChange}
      >
        {EyebrowTypes.map(item => (
          <option key={item.id} value={item.name}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormGroup>

    <FormGroup>
      <Label for="mouthType" className="avatar-form-label">
        Mouth
      </Label>
      <Select
        type="select"
        name="mouthType"
        id="mouthType"
        value={props.mouthType}
        onChange={props.onChange}
      >
        {MouthTypes.map(item => (
          <option key={item.id} value={item.name}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormGroup>

    <FormGroup>
      <Label for="skinColor" className="avatar-form-label">
        Skin
      </Label>
      <Select
        type="select"
        name="skinColor"
        id="skinColor"
        value={props.skinColor}
        onChange={props.onChange}
      >
        {SkinTypes.map(item => (
          <option key={item.id} value={item.name}>
            {item.label}
          </option>
        ))}
      </Select>
    </FormGroup>
  </div>
);

export default AvatarForm;
