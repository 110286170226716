import axios from "axios";
import flow from "lodash/flow";

const ACTIVITY_META_DATA = "/activitylog";
const RESULT = "Student navigated to a story";
const LIBRARYRESULT = "Student navigated to library ";
const SURVEY_CLICKED = "Clicked through to survey";

export function activityLog(item) {
  return axios.post(ACTIVITY_META_DATA, item).then(({ data }) => data);
}

const logClick = ({ result }) => {
  return {
    result
  };
};

const libraryClick = () => {
  return {
    result: LIBRARYRESULT
  };
};

const surveyClick = () => {
  return {
    result: SURVEY_CLICKED
  };
};

const publishStoryClicked = ({ storyId }) => {
  return {
    storyId,
    result: RESULT
  };
};

const trackNotificationClicked = ({ result, storyId }) => {
  return {
    result,
    storyId
  };
};

export const logOnClick = flow(
  logClick,
  activityLog
);
export const publishStoryClick = flow(
  publishStoryClicked,
  activityLog
);
export const trackNotificationClick = flow(
  trackNotificationClicked,
  activityLog
);
export const trackLibraryClick = flow(
  libraryClick,
  activityLog
);
export const logSurveyClick = flow(
  surveyClick,
  activityLog
);
