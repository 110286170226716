import find from "lodash/find";
import first from "lodash/first";
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { StorageService } from "../services";
import { ScrollToTop } from "../utils";
import { Loading } from "../common/Loading/Loading";
// import { validateReset } from "../views/Accounts/accountApi";
import {
  AdminDashboard,
  AvatarChange,
  ClassmateWork,
  GenreTagging,
  Library,
  Login,
  PagenotFound,
  ProfilePage,
  Register,
  StoryTypeCategory,
  StudentDashboard,
  TeacherDashboard,
  TeacherRegister,
  TeacherReview,
  TeacherViewStory,
  Unsubscribe,
  UploadFrontCover,
  Welcome,
  Workspace,
  SchoolRegister,
  StorySummary,
  CompDashboard,
  CompResults,
  ForgotPassword,
  ResetPassword,
  TeacherSufixx,
  TeacherGroupDashboard,
  TeacherModerator
} from "../views";
import {
  DashboardReview,
  StoriesReview,
  StoryReview,
} from "../views/Administrator";
import { RouteNames } from "./RouteNames";

const getVariableData = str => {
  if (str && str.indexOf("=") >= 0) {
    const keyValue = str.split("=");
    return { Name: keyValue[0], Value: keyValue[1] };
  }
  return null;
};
const getQueryString = () => {
  const queryString = window.location.href;
  const keyValuePairs = [];
  if (!queryString) return keyValuePairs;

  const queryVars = queryString.replace("?", "").split("&");
  for (let i = 0; i < queryVars.length; i += 1) {
    keyValuePairs.push(getVariableData(queryVars[i]));
  }
  return keyValuePairs;
};
class Routes extends Component {
  render() {
    let loadingImg = "";
    if (this.props.isAuthenticated) loadingImg = <Loading />;

    return (
      <Router>
        <ScrollToTop>
          <Suspense fallback={loadingImg}>
            <Switch>
              <PublicRoute
                exact
                path={RouteNames.Default.route}
                component={Login}
                {...this.props}
              />
              <Route path={RouteNames.Login.route} render={() => <Login />} />
              <Route
                path={RouteNames.Unsubscribe.route}
                render={() => <Unsubscribe />}
              />
              <Route
                path={RouteNames.UnsubscribePublishStoryEmail.route}
                render={() => <Unsubscribe />}
              />
              <Route
                path={RouteNames.UnsubscribeTeacherFeedbackEmail.route}
                render={() => <Unsubscribe />}
              />
              <Route
                path={RouteNames.UnsubscribePersonalFeedbackEmail.route}
                render={() => <Unsubscribe />}
              />
              <Route
                path={RouteNames.UnsubscribeClassmateStoryPublishEmail.route}
                render={() => <Unsubscribe />}
              />
              <Route
                path={RouteNames.UnsubscribePeopleReadingYourWorkEmail.route}
                render={() => <Unsubscribe />}
              />
              <Route
                path={RouteNames.UnsubscribePeopleReactedToYourWorkEmail.route}
                render={() => <Unsubscribe />}
              />
              <Route
                path={
                  RouteNames.UnsubscribeTeacherApprovesPersonalFeedbackEmail
                    .route
                }
                render={() => <Unsubscribe />}
              />
              <Route
                path={
                  RouteNames.UnsubscribeTeacherDeniedPersonalFeedbackEmail.route
                }
                render={() => <Unsubscribe />}
              />
              <Route
                path={RouteNames.Register.route}
                render={() => <Register />}
              />
              <Route
                path={RouteNames.SchoolRegister.route}
                render={() => <SchoolRegister />}
              />
              <Route
                path={RouteNames.TeacherRegister.route}
                render={() => <TeacherRegister />}
              />
              <PrivateRoute
                path={RouteNames.StudentDashboard.route}
                userRoles={RouteNames.StudentDashboard.roles}
                component={StudentDashboard}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.Welcome.route}
                userRoles={RouteNames.Welcome.roles}
                component={Welcome}
                {...this.props}
              />
              <PrivateRoute
                path={`${RouteNames.Workspace.route}/:id`}
                userRoles={RouteNames.Workspace.roles}
                component={Workspace}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.Workspace.route}
                userRoles={RouteNames.Workspace.roles}
                component={Workspace}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.StoryTypeCategory.route}
                userRoles={RouteNames.StoryTypeCategory.roles}
                component={StoryTypeCategory}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.StorySummary.route}
                userRoles={RouteNames.StorySummary.roles}
                component={StorySummary}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.CompDashboard.route}
                userRoles={RouteNames.CompDashboard.roles}
                component={CompDashboard}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.CompResults.route}
                userRoles={RouteNames.CompResults.roles}
                component={CompResults}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.TeacherReview.route}
                userRoles={RouteNames.TeacherReview.roles}
                component={TeacherReview}
                {...this.props}
              />
              <PrivateRoute
                path={`${RouteNames.TeacherGroupDashboard.route}/:userGroupId`}
                userRoles={RouteNames.TeacherGroupDashboard.roles}
                component={TeacherGroupDashboard}
                {...this.props}
              />

              <PrivateRoute
                path={RouteNames.TeacherGroupDashboard.route}
                userRoles={RouteNames.TeacherGroupDashboard.roles}
                component={TeacherGroupDashboard}
                {...this.props}
              />

                <PrivateRoute
                path={RouteNames.TeacherModeratorDashboard.route}
                userRoles={RouteNames.TeacherModeratorDashboard.roles}
                component={TeacherModerator}
                {...this.props}
              />

              <PrivateRoute
                path={RouteNames.TeacherDashboard.route}
                userRoles={RouteNames.TeacherDashboard.roles}
                component={TeacherDashboard}
                {...this.props}
              />
                 <PrivateRoute
                path={RouteNames.TeacherSufixx.route}
                userRoles={RouteNames.TeacherSufixx.roles}
                component={TeacherSufixx}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.TeacherLibrary.route}
                userRoles={RouteNames.TeacherLibrary.roles}
                component={Library}
                {...this.props}
              />

              <PrivateRoute
                path={RouteNames.ViewPublishCompStory.route}
                userRoles={RouteNames.ViewPublishCompStory.roles}
                component={TeacherViewStory}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.ViewPublishStory.route}
                userRoles={RouteNames.ViewPublishStory.roles}
                component={TeacherViewStory}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.ViewSubmitCompStory.route}
                userRoles={RouteNames.ViewSubmitCompStory.roles}
                component={TeacherViewStory}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.ViewSubmitStory.route}
                userRoles={RouteNames.ViewSubmitStory.roles}
                component={TeacherViewStory}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.ViewPendingComments.completeRoute}
                userRoles={RouteNames.ViewPendingComments.roles}
                component={TeacherViewStory}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.ClassmateWork.route}
                userRoles={RouteNames.ClassmateWork.roles}
                component={ClassmateWork}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.StoryWork.route}
                userRoles={RouteNames.StoryWork.roles}
                component={ClassmateWork}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.GenreTagging.route}
                userRoles={RouteNames.GenreTagging.roles}
                component={GenreTagging}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.UploadFrontCover.route}
                userRoles={RouteNames.UploadFrontCover.roles}
                component={UploadFrontCover}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.AdminDashboard.route}
                userRoles={RouteNames.AdminDashboard.roles}
                component={AdminDashboard}
                {...this.props}
              />
              <PrivateRoute
                path={`${
                  RouteNames.TeacherDashboardReview.route
                }/:schoolId/:groupId`}
                userRoles={RouteNames.TeacherDashboardReview.roles}
                component={DashboardReview}
                {...this.props}
              />
              <PrivateRoute
                path={`${RouteNames.TeacherDashboardReview.route}/:schoolId`}
                userRoles={RouteNames.TeacherDashboardReview.roles}
                component={DashboardReview}
                {...this.props}
              />
              <PrivateRoute
                path={`${
                  RouteNames.AdminSubmittedStoriesReview.route
                }/:schoolId/:studentId`}
                userRoles={RouteNames.AdminSubmittedStoriesReview.roles}
                component={StoriesReview}
                {...this.props}
              />
              <PrivateRoute
                path={`${
                  RouteNames.AdminPendingCommentsReview.route
                }/:schoolId/:studentId`}
                userRoles={RouteNames.AdminPendingCommentsReview.roles}
                component={StoriesReview}
                {...this.props}
              />
              <PrivateRoute
                path={`${
                  RouteNames.AdminPublishedStoriesReview.route
                }/:schoolId/:studentId`}
                userRoles={RouteNames.AdminPublishedStoriesReview.roles}
                component={StoriesReview}
                {...this.props}
              />
              <PrivateRoute
                path={`${RouteNames.AdminStoryReview.route}/:schoolId/:storyId`}
                userRoles={RouteNames.AdminStoryReview.roles}
                component={StoryReview}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.StudentLibrary.route}
                userRoles={RouteNames.StudentLibrary.roles}
                component={Library}
                {...this.props}
              />

              <PrivateRoute
                path={`${RouteNames.UserProfilePage.route}/:profileId?`}
                userRoles={RouteNames.UserProfilePage.roles}
                component={ProfilePage}
                {...this.props}
              />
              <PrivateRoute
                path={RouteNames.ChangeAvatar.route}
                userRoles={RouteNames.ChangeAvatar.roles}
                component={AvatarChange}
                {...this.props}
              />
              <Route
                path={RouteNames.ForgotPassword.route}
                render={() => <ForgotPassword />}
              />

              <Route
                path={RouteNames.ResetPassword.route}
                render={() => <ResetPassword />}
              />

              <Route component={PagenotFound} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Router>
    );
  }
}

const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = rest.isAuthenticated || StorageService.tokenExists();
  const roles = rest.roles;

  if (!(isAuthenticated && roles)) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  const User = {
    TEACHER: "/teacher-dashboard",
    STUDENT: "/student-dashboard",
    ADMIN: "/admin"
  };

  const pathname = User[roles[0]] || RouteNames.Login.route;
  return (
    <Route
      {...rest}
      render={props => (
        <Redirect
          to={{
            pathname,
            state: { from: props.location }
          }}
        />
      )}
    />
  );
};
const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = rest.isAuthenticated || StorageService.tokenExists();
  const hasRoleMatch = find(rest.userRoles, item => item === first(rest.roles));

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated && hasRoleMatch ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = ({ user }) => {
  const { isAuthenticated, roles, displayPic, avatar } = user;
  return { isAuthenticated, roles, displayPic, avatar };
};
export default connect(
  mapStateToProps,
  null
)(Routes);
