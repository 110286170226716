const EyeTypes = [
  {
    id: 1,
    label: 'Close',
    name: 'Close',
  },
  {
    id: 2,
    label: 'Cry',
    name: 'Cry',
  },
  {
    id: 3,
    label: 'Default',
    name: 'Default',
  },
  {
    id: 4,
    label: 'Dizzy',
    name: 'Dizzy',
  },
  {
    id: 5,
    label: 'Eye Roll',
    name: 'EyeRoll',
  },
  {
    id: 6,
    label: 'Happy',
    name: 'Happy',
  },
  {
    id: 7,
    label: 'Hearts',
    name: 'Hearts',
  },
  {
    id: 8,
    label: 'Side',
    name: 'Side',
  },
  {
    id: 9,
    label: 'Squint',
    name: 'Squint',
  },
  {
    id: 10,
    label: 'Surprised',
    name: 'Surprised',
  },
  {
    id: 11,
    label: 'Wink',
    name: 'Wink',
  },
  {
    id: 12,
    label: 'Wacky wink',
    name: 'WinkWacky',
  },
];

export default EyeTypes;
