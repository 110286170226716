import React from "react";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";

import "./profileIcon.css";
import { AccountOn, AccountOff } from "../../../assets/images";

const ProfileIcon = ({ onClick, avatar, hover, dropdownOpen }) => {
  const classHover = classNames(
    "header-profile-btn",
    hover ? "hover-avatar" : "no-hover-avatar"
  );
  return (
    <div className={classHover} onClick={onClick}>
      {avatar && !some(avatar, isEmpty) ? (
        <img
          className="header-profile-image"
          src={AccountOn}
          alt="notification"
        />
      ) : (
        <img
          className="header-profile-image"
          src={dropdownOpen ? AccountOn : AccountOff}
          alt="notification"
        />
      )}
    </div>
  );
};

export { ProfileIcon };
