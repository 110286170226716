const MouthTypes = [
  {
    id: 1,
    label: 'Concerned',
    name: 'Concerned',
  },
  {
    id: 2,
    label: 'Default',
    name: 'Default',
  },
  {
    id: 3,
    label: 'Disbelief',
    name: 'Disbelief',
  },
  {
    id: 4,
    label: 'Eating',
    name: 'Eating',
  },
  {
    id: 5,
    label: 'Grimace',
    name: 'Grimace',
  },
  {
    id: 6,
    label: 'Sad',
    name: 'Sad',
  },
  {
    id: 7,
    label: 'Scream',
    name: 'ScreamOpen',
  },
  {
    id: 8,
    label: 'Serious',
    name: 'Serious',
  },
  {
    id: 9,
    label: 'Smile',
    name: 'Smile',
  },
  {
    id: 10,
    label: 'Tongue',
    name: 'Tongue',
  },
  {
    id: 11,
    label: 'Twinkle',
    name: 'Twinkle',
  },
  {
    id: 12,
    label: 'Vomit',
    name: 'Vomit',
  },
];

export default MouthTypes;
