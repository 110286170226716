import React from "react";
import Select from "react-select";

const SelectDropDown = ({
  closeMenuOnSelect,
  isMulti,
  onChange,
  options,
  value
}) => (
  <Select
    closeMenuOnSelect={closeMenuOnSelect}
    isMulti={isMulti}
    options={options}
    onChange={onChange}
    value={value}
  />
);

export { SelectDropDown };
