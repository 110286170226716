import axios from "axios";

export function grayLog(error) {
  let sendLog = true;

  if (error.config && error.config.url.indexOf('graylog') > -1) {
    sendLog = false;
  }

  if (sendLog)
    axios.post('/graylog', { "message": JSON.stringify(error) })
      .catch(response => {/* handle promise rejection */ });
}
export function consoleGrayLog() {
  console.warn = console.error = (msg) => {
    grayLog(msg);
  };
}