const ClotheColors = [
  {
    id: 1,
    label: 'Black',
    name: 'Black',
  },
  {
    id: 2,
    label: 'Baby blue',
    name: 'Blue01',
  },
  {
    id: 3,
    label: 'Sky blue',
    name: 'Blue02',
  },
  {
    id: 4,
    label: 'Navy Blue',
    name: 'Blue03',
  },
  {
    id: 5,
    label: 'Light grey',
    name: 'Gray01',
  },
  {
    id: 6,
    label: 'Dark grey',
    name: 'Gray02',
  },
  {
    id: 7,
    label: 'Heather',
    name: 'Heather',
  },
  {
    id: 8,
    label: 'Pastel blue',
    name: 'PastelBlue',
  },
  {
    id: 9,
    label: 'Pastel green',
    name: 'PastelGreen',
  },
  {
    id: 10,
    label: 'Pastel orange',
    name: 'PastelOrange',
  },
  {
    id: 11,
    label: 'Pastel red',
    name: 'PastelRed',
  },
  {
    id: 12,
    label: 'Pastel yellow',
    name: 'PastelYellow',
  },
  {
    id: 13,
    label: 'Pink',
    name: 'Pink',
  },
  {
    id: 14,
    label: 'Red',
    name: 'Red',
  },
  {
    id: 15,
    label: 'White',
    name: 'White',
  },
];

export default ClotheColors;
