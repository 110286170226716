import React, { Component } from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import ProfileImage from "../../../assets/images/profile/default-profile-small.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../LogoutButton/logoutAction";
import { RouteNames } from "../../../Routes/RouteNames";

class AdminMenu extends Component {
  state = {
    dropdownOpen: false
  };

  navigateToTeacherDashboard = () => {
    const { history, match, location } = this.props;
    const { pathname } = location;
    const { schoolId } = match.params;
    if (pathname !== RouteNames.TeacherDashboardReview.route)
      history.push(`${RouteNames.TeacherDashboardReview.route}/${schoolId}`);
  };

  navigateAdminToDashboard = () => {
    const { history, location } = this.props;
    const { pathname } = location;
    if (pathname !== RouteNames.AdminDashboard.route)
      history.push(RouteNames.AdminDashboard.route);
  };

  render() {
    return (
      <div className="teacher-profile-btn">
        <ButtonDropdown
          className="dropdown-wrapper"
          isOpen={this.state.dropdownOpen}
          toggle={() =>
            this.setState({ dropdownOpen: !this.state.dropdownOpen })
          }
        >
          <DropdownToggle className="btn-secondary-bg-none">
            <img
              className="header-profile-image"
              src={ProfileImage}
              alt="notification"
            />
          </DropdownToggle>

          <DropdownMenu className="dropdown-align-teacher">
            <DropdownItem
              onClick={this.navigateAdminToDashboard}
              className="dropdown-item-setting"
            >
              Admin dashboard
            </DropdownItem>
            <DropdownItem
              onClick={this.navigateToTeacherDashboard}
              className="dropdown-item-setting"
            >
              Teacher dashboard
            </DropdownItem>
            <DropdownItem
              onClick={this.props.logout}
              className="dropdown-item-setting"
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

export default connect(
  null,
  { logout }
)(withRouter(AdminMenu));
