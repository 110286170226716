const SkinTypes = [
  {
    id: 1,
    label: 'Tanned',
    name: 'Tanned',
  },
  {
    id: 2,
    label: 'Yellow',
    name: 'Yellow',
  },
  {
    id: 3,
    label: 'Pale',
    name: 'Pale',
  },
  {
    id: 4,
    label: 'Light',
    name: 'Light',
  },
  {
    id: 5,
    label: 'Brown',
    name: 'Brown',
  },
  {
    id: 6,
    label: 'Dark brown',
    name: 'DarkBrown ',
  },
  {
    id: 7,
    label: 'Black',
    name: 'Black',
  },
];

export default SkinTypes;
