import React from 'react';
import "./ProgressDots.css";

class ProgressDots extends React.Component {

    render() {
        return (
            <div className="progress-dots">
                {this.renderConfiguration()}
            </div>
        )
    }

    renderConfiguration = () => {
        const { current } = this.props;

        switch (current) {
            case 1: return (
                <React.Fragment>
                    <div className="current-dot dot"></div>
                    <div className="inactive-dot dot"></div>
                    <div className="inactive-dot dot"></div>
                    <div className="inactive-dot dot"></div>
                </React.Fragment>
            )

            case 2: return (
                <React.Fragment>
                    <div className="past-dot dot"></div>
                    <div className="current-dot dot"></div>
                    <div className="inactive-dot dot"></div>
                    <div className="inactive-dot dot"></div>
                </React.Fragment>
            )

            case 3: return (
                <React.Fragment>
                    <div className="past-dot dot"></div>
                    <div className="past-dot dot"></div>
                    <div className="current-dot dot"></div>
                    <div className="inactive-dot dot"></div>
                </React.Fragment>
            )

            case 4: return (
                <React.Fragment>
                    <div className="past-dot dot"></div>
                    <div className="past-dot dot"></div>
                    <div className="past-dot dot"></div>
                    <div className="current-dot dot"></div>
                </React.Fragment>
            )

            case -1: return (
                <React.Fragment>
                    <div className="inactive-dot dot"></div>
                    <div className="inactive-dot dot"></div>
                    <div className="inactive-dot dot"></div>
                    <div className="inactive-dot dot"></div>
                </React.Fragment>
            )

            default: return (
                <React.Fragment>
                    <div className="past-dot dot"></div>
                    <div className="past-dot dot"></div>
                    <div className="past-dot dot"></div>
                    <div className="past-dot dot"></div>
                </React.Fragment>
            )
        }
    }

}

export default ProgressDots;