import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./app-modal.css";

class AppModal extends Component {
  render() {
    const {
      toggle,
      icon,
      title,
      description,
      children,
      isOpen,
      backdrop,
      customWrapeClass
    } = this.props;

    return (
      <Modal
        className="modal-popup"
        isOpen={isOpen}
        centered={true}
        toggle={toggle}
        backdrop={backdrop}
      >
        <div className={customWrapeClass ? customWrapeClass : ""}>
          <ModalHeader toggle={toggle} />
          <ModalBody>
            {icon ? (
              <img
                src={icon}
                alt="image"
                style={{ height: customWrapeClass ? "150px" : "auto" }}
              />
            ) : null}
            <h3>{title}</h3>
            <p className="modelDescription">{description}</p>
          </ModalBody>

          <ModalFooter>{children}</ModalFooter>
        </div>
      </Modal>
    );
  }
}

export default AppModal;
