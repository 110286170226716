import React, { Component } from "react";
import { Label, FormFeedback, FormGroup, Input } from "reactstrap";
import "./async-react-select.css";
import { Loading } from "../Loading/Loading";

class AsyncReactSelect extends Component {
  state = { isBlur: false };

  handleInputBlur = () => this.setState({ isBlur: true });
  handleInputFocus = () => this.setState({ isBlur: false });

  render() {
    const {
      label,
      name,
      errorMessage,
      invalid,
      value,
      options,
      onChange,
      isFetching,
      onSelectOption
    } = this.props;
    const { isBlur } = this.state;
    return (
      <FormGroup className="input-form-group">
        {label ? <Label for={name}>{label}</Label> : null}
        <div className="input-with-loader">
          <Input
            type="input"
            name={name}
            invalid={invalid}
            onChange={onChange}
            onBlur={this.handleInputBlur}
            value={value}
            autocomplete="off"
            onFocus={this.handleInputFocus}
          />
          {isFetching && <Loading />}
          {((errorMessage && !value) ||
            (errorMessage && isBlur) ||
            options.length <= 0) && <FormFeedback>{errorMessage}</FormFeedback>}
        </div>
        {value && !isBlur && (
          <ul className="select-list">
            {options.map(item => (
              <li
                key={item.id}
                onMouseDown={() => onSelectOption(item)}
                onClick={this.handleInputBlur}
              >
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </FormGroup>
    );
  }
}

export { AsyncReactSelect };
