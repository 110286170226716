import { AuthService } from ".";

const TOKEN_KEY = "Token";
const USER_INFO = "User";

export class StorageService {
  static saveToken = ({ token, user }) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USER_INFO, JSON.stringify(user));
    AuthService.setDefaultAuthHeaders();
  };

  static tokenExists = () => !!StorageService.getToken();

  static getToken = () => localStorage.getItem(TOKEN_KEY);

  static getUser = () => {
    const user = localStorage.getItem(USER_INFO);
    return user ? JSON.parse(user) : null;
  };

  static clearToken = () => localStorage.removeItem(TOKEN_KEY);

  static clearAll = () => localStorage.clear();
}
export default new StorageService();
