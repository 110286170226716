import React from "react";
import "./menuButton.css";

const MenuButton = ({ onClick, icon, customClass }) => (
  <button
    className={`libray-btn header-icon ${customClass ? customClass : ""}`}
    onClick={onClick}
  >
    <img className="library-image" src={icon} alt="library" />
  </button>
);

export { MenuButton };
