import React, { Component } from "react";
import PropTypes from "prop-types";
import "./center-content.css";

class CenterContent extends Component {
  render() {
    const { children, className } = this.props;
    return <div className={`center-content ${className}`}>{children}</div>;
  }
}

CenterContent.defaultProps = {
  className: ""
};
CenterContent.propTypes = {
  children: PropTypes.node
};

export { CenterContent };
