import { ActionTypes } from "../action";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import { EmptyAvatar } from "../utils";

const INITIAL_STATE = {
  isAuthenticated: false,
  firstName: "",
  lastName: "",
  userId: "",
  roles: [],
  displayPic: false,
  avatar: EmptyAvatar
};
export default (state, action) => {
  if (!state) state = INITIAL_STATE;
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      const {
        firstName,
        lastName,
        userId,
        displayPic,
        roles,
        avatar
      } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        firstName: firstName,
        lastName: lastName,
        userId: userId,
        roles: roles,
        displayPic: displayPic,
        avatar: {
          hasAvatar: !some(avatar, isEmpty),
          ...avatar
        }
      };
    case ActionTypes.LOGOUT:
      return INITIAL_STATE;
    case ActionTypes.ADD_AVATAR:
      return {
        ...state,
        avatar: {
          hasAvatar: !some(action.payload, isEmpty),
          ...action.payload
        }
      };
    default:
      return state;
  }
};
