const Accessories = [
  {
    id: 1,
    label: 'None',
    name: 'Blank',
  },
  {
    id: 2,
    label: 'Big circle shades',
    name: 'Kurt',
  },
  {
    id: 3,
    label: 'Light prescription glasses',
    name: 'Prescription01',
  },
  {
    id: 4,
    label: 'Dark prescription glasses',
    name: 'Prescription02',
  },
  {
    id: 5,
    label: 'Round black glasses',
    name: 'Round',
  },
  {
    id: 6,
    label: 'Dark shades',
    name: 'Sunglasses',
  },
  {
    id: 7,
    label: 'Square dark shades',
    name: 'Wayfarers',
  },
];

export default Accessories;
