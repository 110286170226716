export const RouteNames = {
  Default: {
    route: "/",
    roles: []
  },
  Login: {
    route: "/login",
    roles: []
  },

  Workspace: {
    route: "/workspace",
    roles: ["STUDENT"]
  },

  Welcome: {
    route: "/welcome",
    roles: ["STUDENT", "TEACHER"]
  },

  StudentDashboard: {
    route: "/student-dashboard",
    roles: ["STUDENT"]
  },
  TeacherGroupDashboard: {
    route: "/teacher-group",
    roles: ["TEACHER","MODERATOR"]
  },

  TeacherModeratorDashboard: {
    route: "/moderator-group",
    roles: ["MODERATOR"]
  },

  TeacherDashboard: {
    route: "/teacher-dashboard",
    roles: ["TEACHER"]
  },
  TeacherSufixx: {
    route: "/teacher-sufixx",
    roles: ["TEACHER"]
  },

  TeacherReview: {
    route: "/teacher-review/:storyId",
    roles: ["TEACHER","MODERATOR"]
  },
  TeacherLibrary: {
    route: "/teacher-library",
    roles: ["TEACHER"]
  },
  ViewSubmitStory: {
    route: "/view-submit-stories/:studentId",
    roles: ["TEACHER","MODERATOR"]
  },
  ViewSubmitCompStory: {
    route: "/view-submit-stories/:studentId/:compId",
    roles: ["TEACHER","MODERATOR"]
  },
  ViewPendingComments: {
    route: "/view-pending-comments",
    completeRoute: "/view-pending-comments/:studentId",
    roles: ["TEACHER","MODERATOR"]
  },

  ViewPublishCompStory: {
    route: "/view-publish-stories/:studentId/:compId",
    roles: ["TEACHER","MODERATOR"]
  },

  ViewPublishStory: {
    route: "/view-publish-stories/:studentId",
    roles: ["TEACHER" ,"MODERATOR"]
  },

  ClassmateWork: {
    route: "/classmate-work/:storyId",
    roles: ["STUDENT"]
  },

  StoryWork: {
    route: "/story-work/:storyId",
    roles: ["STUDENT"]
  },

  PagenotFound: {
    route: "/pagenot-found",
    roles: []
  },

  AdminDashboard: {
    route: "/admin",
    roles: ["ADMIN"]
  },

  TeacherDashboardReview: {
    route: "/dashboard-review",
    roles: ["ADMIN"]
  },

  AdminSubmittedStoriesReview: {
    route: "/admin-submit-stories-review",
    roles: ["ADMIN"]
  },
  AdminPendingCommentsReview: {
    route: "/admin-pending-comments-review",
    roles: ["ADMIN"]
  },

  AdminPublishedStoriesReview: {
    route: "/admin-publish-stories-review",
    roles: ["ADMIN"]
  },

  AdminStoryReview: {
    route: "/admin-story-review",
    roles: ["ADMIN"]
  },

  StoryTypeCategory: {
    route: "/select-type",
    roles: ["STUDENT"]
  },
  StorySummary: {
    route: "/story-summary",
    roles: ["STUDENT"]
  },
  CompDashboard: {
    route: "/competition-dashboard",
    roles: ["STUDENT"]
  },
  CompResults: {
    route: "/competition-results",
    roles: ["STUDENT"]
  },
  Register: {
    route: "/register",
    roles: ["STUDENT"]
  },
  SchoolRegister: {
    route: "/school-register",
    roles: ["TEACHER"]
  },
  TeacherRegister: {
    route: "/teacher-register",
    roles: ["TEACHER"]
  },

  GenreTagging: {
    route: "/select-genre/:storyId",
    roles: ["STUDENT"]
  },

  UploadFrontCover: {
    route: "/upload-front-cover/:storyId",
    roles: ["STUDENT"]
  },

  StudentLibrary: {
    route: "/library",
    roles: ["STUDENT"]
  },

  UserProfilePage: {
    route: "/profile",
    roles: ["STUDENT"]
  },
  ChangeAvatar: {
    route: "/update-avatar",
    roles: ["STUDENT", "TEACHER"]
  },

  Unsubscribe: {
    route: "/unsubscribe",
    roles: ["TEACHER"]
  },
  UnsubscribePublishStoryEmail: {
    route: "/unsubscribe/teacher-publish-story-feedback/:userId",
    roles: ["STUDENT"]
  },
  UnsubscribeTeacherFeedbackEmail: {
    route: "/unsubscribe/teacher-sent-feedback/:userId",
    roles: ["STUDENT"]
  },
  UnsubscribePersonalFeedbackEmail: {
    route: "/unsubscribe/personal-feedback/:userId",
    roles: ["STUDENT"]
  },
  UnsubscribeClassmateStoryPublishEmail: {
    route: "/unsubscribe/classmate-story-publish/:userId",
    roles: ["STUDENT"]
  },
  UnsubscribePeopleReadingYourWorkEmail: {
    route: "/unsubscribe/people-reading-your-work/:userId",
    roles: ["STUDENT"]
  },
  UnsubscribePeopleReactedToYourWorkEmail: {
    route: "/unsubscribe/people-reacted-to-your-work/:userId",
    roles: ["STUDENT"]
  },
  UnsubscribeTeacherApprovesPersonalFeedbackEmail: {
    route: "/unsubscribe/teacher-approves-personal-feedback/:userId",
    roles: ["STUDENT"]
  },
  UnsubscribeTeacherDeniedPersonalFeedbackEmail: {
    route: "/unsubscribe/teacher-denied-personal-feedback/:userId",
    roles: ["STUDENT"]
  },
  ForgotPassword: {
    route: "/forgot-password",
    roles: ["TEACHER"]
  },
  ResetPassword: {
    route: "/reset-password-link",
    roles: ["TEACHER"]
  }
};
