import React from "react";
import "./pagecenter.css";
import PropsTypes from "prop-types";
const PageCenter = ({ children, className }) => (
  <div className={`page-center2 ${className}`}>{children}</div>
);
PageCenter.prototype = {
  className: PropsTypes.string
};
export { PageCenter };
