export const CommentList = [
  { id: 1, name: "Great story!", commentType: "GREAT_STORY" },
  {
    id: 2,
    name: "Enjoyed reading this story",
    commentType: "ENJOYED_READING_THIS_STORY"
  },
  { id: 3, name: "Loved the characters", commentType: "LOVED_THE_CHARACTERS" },
  { id: 4, name: "Very moving", commentType: "VERY_MOVING" },
  { id: 5, name: "Very funny!", commentType: "VERY_FUNNY" },
  { id: 6, name: "Thought provoking", commentType: "THOUGHT_PROVOKING" },
  { id: 7, name: "Great imagination", commentType: "GREAT_IMAGINATION" }
];
