import React, { Component } from "react";
import RobotImage from "../../assets/images/robot.svg";

import "./story-robot.css";

class StoryRobot extends Component {
  render() {
    const { children, center } = this.props;
    return (
      <div className={`story-robot ${center ? "screen-center" : ""}`}>
        <div className="robot-wrap">
          <img src={RobotImage} alt="robot pic" className="robot-image" />
        </div>
        <p>{children}</p>
      </div>
    );
  }
}

export { StoryRobot };
