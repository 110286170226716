import axios from "axios";
import { ActionTypes } from "../../../action";

const USER_DP = "/user/dp/";
const USER_AVATAR = "/user/avatar";
const USER_HAS_AVATAR = `${USER_AVATAR}/exists`;

export const fetchUserImageApi = () =>
  axios.get(USER_DP).then(({ data }) => data);

export function uploadUserImageApi(fileObj) {
  const data = new FormData();
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  data.append("file", fileObj);

  return axios.post(USER_DP, data, config).then(({ data }) => data);
}

export const saveAvatarApi = avatar =>
  axios.put(USER_AVATAR, { ...avatar }).then(({ data }) => data);

export const getAvatar = () => axios.get(USER_AVATAR).then(({ data }) => data);

export const saveAvatarAction = avatar => dispatch =>
  saveAvatarApi(avatar).then(data => {
    dispatch({
      type: ActionTypes.ADD_AVATAR,
      payload: avatar
    });

    return data;
  });

export const userHasAvatar = () =>
  axios.get(USER_HAS_AVATAR).then(({ data }) => data);
