import React, { Component } from "react";
import Avatar from "avataaars";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import DefaultImage from "../../assets/images/profile/default.png";
import ReactHtmlParser from "react-html-parser";

import "./profile.css";

class Profile extends Component {
  render() {
    const { userInfo, onAvatarClick, isTeacher } = this.props;

    return (
      <div className="profile">
        <div onClick={onAvatarClick}>
          {!some(userInfo.avatar, isEmpty) ? (
            <div
              className={
                isTeacher
                  ? "profile-image-wrapper"
                  : "profile-image-wrapper hover-profile"
              }
            >
              <Avatar
                className="avatar-image"
                avatarStyle="Circle"
                style={{ width: "200px", height: "200px" }}
                {...userInfo.avatar}
              />
            </div>
          ) : (
            <div
              className={
                isTeacher
                  ? "profile-image-wrapper"
                  : "profile-image-wrapper hover-profile"
              }
            >
              <img
                src={DefaultImage}
                alt="Default profile pic."
                class="defaultDashPro"
              />
            </div>
          )}
        </div>

        <div className="profile-detail">
          <h2>{userInfo.welcomeText}</h2>
          {isTeacher ? <h2> {userInfo.firstName}</h2> : null}
          <div className="test">
            <p>{ReactHtmlParser(userInfo.followMsg)}</p>
            <p>{ReactHtmlParser(userInfo.viewsMsg)}</p>
          </div>
        </div>
      </div>
    );
  }
}

export { Profile };
