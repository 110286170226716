import React, { Component } from "react";
import { Button } from "reactstrap";
import "./commenteditor.css";
const ListItem = ({ children, onClick, isSelected }) => (
  <div
    onClick={onClick}
    className={`list-item ${isSelected ? "selected" : ""}`}
  >
    {children}
  </div>
);
const List = ({ children, isSelected }) => (
  <div className={`list ${isSelected ? "selected" : ""}`}>{children}</div>
);
const EmojisList = ({ children, isSelected }) => (
  <div className={`emojis-list ${isSelected ? "selected" : ""}`}>
    {children}
  </div>
);
const EmojisItem = ({ children, onClick, emoji, isSelected }) => (
  <div
    onClick={onClick}
    className={`emojis-item emoji-${emoji} ${isSelected ? "selected" : ""}`}
  >
    {children}
  </div>
);
const Submit = ({ children, onClick, disableBtn }) => (
  <div className="action-buttons submiteActionWrap">
    <Button
      onClick={onClick}
      className="submit-button round-button"
      disabled={disableBtn}
    >
      {children}
    </Button>
  </div>
);
class CommentEditor extends Component {
  static List = List;
  static ListItem = ListItem;
  static EmojisItem = EmojisItem;
  static EmojisList = EmojisList;
  static Submit = Submit;
  render() {
    const { children, className } = this.props;
    return <div className={`comments-editor ${className}`}>{children}</div>;
  }
}

export { CommentEditor };
