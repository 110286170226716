import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "babel-polyfill";
import "isomorphic-fetch";
import "bootstrap/dist/css/bootstrap.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/jelly.css";
import "react-image-crop/dist/ReactCrop.css";
import "./assets/sass/style.css";

import { registerObserver } from "react-perf-devtool";
import * as serviceWorker from "./serviceWorker";
registerObserver();
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
