import React, { Component } from "react";
import Routes from "./Routes/Routes";
import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import combineReducer from "./reducer";
import { Provider } from "react-redux";
import { AuthService, StorageService } from "./services";
import { userInformation } from "./views/Accounts/accountActions";
import axios from "axios";
import ReactGA from "react-ga";
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(ReduxThunk)
  // other store enhancers if any
);
export const store = createStore(combineReducer, enhancer);

const user = StorageService.getUser();
if (user) store.dispatch(userInformation({ user }));

class App extends Component {
  constructor(props) {
    super(props);
    this.initializeApp();
  }
  initializeApp() {
    AuthService.setDefaults();

    axios.get("/setting/ga").then(({ data }) => {
      ReactGA.initialize(data.key);
      ReactGA.pageview(window.location.pathname + window.location.search);
    });

    let vh = window.innerHeight * 0.01;
    console.log(vh);
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty(
      "--fh",
      `${window.innerHeight}px`
    );

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.documentElement.style.setProperty(
        "--fh",
        `${window.innerHeight}px`
      );
    });
  }
  render() {
    return (
      <div className="page-scroll">
        <Provider store={store}>
          <Routes />
        </Provider>
      </div>
    );
  }
}

export default App;
