const ClotheTypes = [
  {
    id: 1,
    label: 'Shirt and blazer',
    name: 'BlazerShirt',
    type: 'BLAZER',
  },
  {
    id: 2,
    label: 'Sweater and blazer',
    name: 'BlazerSweater',
    type: 'BLAZER',
  },
  {
    id: 3,
    label: 'Sweater with collar',
    name: 'CollarSweater',
    type: 'SHIRT',
  },
  {
    id: 4,
    label: 'Hoodie',
    name: 'Hoodie',
    type: 'SHIRT',
  },
  {
    id: 5,
    label: 'Overalls',
    name: 'Overall',
    type: 'SHIRT',
  },
  {
    id: 6,
    label: 'Crewneck jumper',
    name: 'ShirtCrewNeck',
    type: 'SHIRT',
  },
  {
    id: 7,
    label: 'Scoopneck jumper',
    name: 'ShirtScoopNeck',
    type: 'SHIRT',
  },
  {
    id: 8,
    label: 'V neck jumper',
    name: 'ShirtVNeck',
    type: 'SHIRT',
  },
];

export default ClotheTypes;
