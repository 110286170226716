import React, { Component } from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../LogoutButton/logoutAction";
import { RouteNames } from "../../../Routes/RouteNames";
import { ProfileIcon } from "../ProfileIcon/ProfileIcon";

class TeacherMenu extends Component {
  state = {
    dropdownOpen: false,
    btnDropright: false,
  };

  navigateToDashboard = () => {
    const { pathname } = this.props.location;
    if (pathname !== RouteNames.TeacherSufixx.route)
      this.props.history.push(RouteNames.TeacherSufixx.route);
  };

  navigateToProfile = () => {
    const { pathname } = this.props.location;
    if (pathname !== RouteNames.ChangeAvatar.route) {
      this.props.history.push(RouteNames.ChangeAvatar.route);
    }
  };
  render() {
    const { avatar, onResetPassword } = this.props;
    return (
      <div className="teacher-profile-btn">
        <ButtonDropdown
          className="dropdown-wrapper"
          isOpen={this.state.dropdownOpen}
          toggle={() =>
            this.setState({ dropdownOpen: !this.state.dropdownOpen })
          }
        >
          <DropdownToggle className="btn-secondary-bg-none">
            <ProfileIcon avatar={avatar} hover />
          </DropdownToggle>

          <DropdownMenu className="dropdown-align-teacher">
            <DropdownItem
              onClick={this.navigateToProfile}
              className="dropdown-item-setting"
            >
              Update avatar
            </DropdownItem>
            <DropdownItem
              onClick={this.navigateToDashboard}
              className="dropdown-item-setting"
            >
              Suffix
            </DropdownItem>
            <ButtonDropdown
              direction="left"
              className="dropdown-item-setting"
              isOpen={this.state.btnDropright}
              onMouseLeave={() => {
                this.setState({ btnDropright: false });
              }}
              onMouseEnter={() => {
                this.setState({ btnDropright: !this.state.btnDropright });
              }}
            >
              <DropdownToggle className="dropdown-item-setting">
                Help Menu
              </DropdownToggle>
              <DropdownMenu className="dropdown-item-setting">
                <a
                  href="https://app.connectedcreatives.co.uk/cdn/help/Teacher-Normal-Flow.pdf"
                  download
                >
                  <DropdownItem className="dropdown-item-setting">
                    Normal Flow
                  </DropdownItem>
                </a>
                <a
                  href="https://app.connectedcreatives.co.uk/cdn/help/Teacher-Flow.pdf"
                  download
                >
                  <DropdownItem className="dropdown-item-setting">
                    Competition Flow
                  </DropdownItem>
                </a>
              </DropdownMenu>
            </ButtonDropdown>

            <DropdownItem
              onClick={onResetPassword}
              className="dropdown-item-setting"
            >
              Reset Password
            </DropdownItem>
            <DropdownItem
              onClick={this.props.logout}
              className="dropdown-item-setting"
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

export default connect(null, { logout })(withRouter(TeacherMenu));
