import React, { Component } from "react";
import IconCard from "../IconCard/IconCard";
import includes from "lodash/includes";
import uuidv1 from "uuid/v1";
import "./iconcontainer.css";

const RowContainer = ({ items, onClick, clickedGenres }) => {
  const itemCards = items.map(item => (
    <div
      className="genre-icon-flex-col iconBox col-6 col-sm-3 col-md-2"
      key={item.id}
    >
      <IconCard
        {...item}
        onClick={onClick}
        selected={includes(clickedGenres, item.id)}
      />
    </div>
  ));

  return <div className="genre-icon-flex-row iconRow row">{itemCards}</div>;
};

class IconContainer extends Component {
  state = {
    iconRows: []
  };
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.clickedGenres == nextProps.clickedGenres;
  }

  static getDerivedStateFromProps(props, state) {
    const chunks = (array, size) => {
      const results = [];
      results.push(array);

      // while (array.length) {
      //   results.push(array.splice(0, size));
      // }
      return results;
    };

    const icons = [...props.icons];
    return {
      iconRows: chunks(icons, 6)
    };
  }

  render() {
    const { onClick, clickedGenres } = this.props;
    const { iconRows } = this.state;

    return (
      <div className="genre-icon-cnt">
        <div className="genreWrap">
          {iconRows.map(row => {
            return (
              <RowContainer
                key={uuidv1()}
                items={row}
                onClick={onClick}
                clickedGenres={clickedGenres}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default IconContainer;
