import React from "react";
import Dropzone from "react-dropzone";

export const UploadFileButton = ({
  isLoading,
  onDrop,
  className,
  children
}) => (
  <div className={`upload-button ${className} ${isLoading ? "disabled" : ""}`}>
    {children}
    <Dropzone
      multiple={false}
      className="dropzone-without-ui"
      accept="image/jpeg, image/png, image/jpg"
      maxSize={20000000}
      onDrop={onDrop}
      disabled={isLoading}
    />
  </div>
);
