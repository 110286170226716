import React, { Component, Fragment } from "react";
import { BackButton } from "./BackButton/BackButton";
import { NotificationBell } from "./NotificationBell/NotificationBell";
import "./header.css";
import {
  getNotificationsApi,
  patchNotificationsApi,
  postNotificationsApi,
  fetchUserAvatar
} from "./headerApi";
import { USER_ROLES } from "../../utils/roles";
import { Notification, ModalPopup } from "..";
import { withRouter } from "react-router-dom";
import findLast from "lodash/findLast";
import find from "lodash/find";
import includes from "lodash/includes";
import {
  trackNotificationClick,
  trackLibraryClick
} from "../../services/activityLogs";
import { connect } from "react-redux";
import { LibraryButton } from "../LibraryButton/LibraryButton";

import { RouteNames } from "../../Routes/RouteNames";
import { logout } from "../LogoutButton/logoutAction";
import StudentMenu from "./Menu/StudentMenu";
import ModeratorMenu from "./Menu/Moderator";
import TeacherMenu from "./Menu/TeacherMenu";
import AdminMenu from "./Menu/AdminMenu";
import { FeedbackModal } from "./Modal/FeedbackModal";
import { ActivityLogger } from "../../utils";
import LogoIcon from "../../assets/images/logo_org.svg";
import LogoIconMD from "../../assets/images/logo_org_md.svg";
import LogoIconSM from "../../assets/images/logo_org_sm.svg";
import {
  AccountOn,
  LibraryOff,
  LibraryOn,
  CompOn,
  CompOff,
  ClassIcon,
  ClassIconOn,
  ClassesOn,
  ClassesOff
} from "../../assets/images";
import ResetPassword from "../ResetPassword/ResetPassword";
import { HamburgerButton, SideNav } from "../../common";
import { CompetitionButton } from "./CompetitionButton/CompetitionButton";
import { getCurrentComp } from "../../views/Competition/competitionAPI";
import { MenuButton } from "../MenuButton/MenuButton";

const Title = ({ children }) => <h5 className="header-title">{children}</h5>;

const Logo = ({ children }) => (
  <div className="header-logo-imgDiv">
    <img className="header-logo-img lg" alt="Logo pic" src={LogoIcon} />
    <img className="header-logo-img md" alt="Logo pic" src={LogoIconMD} />
    <img className="header-logo-img sm" alt="Logo pic" src={LogoIconSM} />
  </div>
);

class Header extends Component {
  static BackButton = BackButton;
  static Bell = NotificationBell;
  static Title = Title;
  static Logo = Logo;
  state = {
    showSliderNotification: false,
    isSideNavShow: false,
    showPopupNotification: false,
    notifications: [],
    popupNotification: {},
    sliderNotifications: [],
    dropdownOpen: false,
    avatar: null,
    isActiveCompetition: false
  };

  componentWillMount() {
    getCurrentComp().then(resp => {
      if (resp.data) {
        this.setState({ isActiveCompetition: true });
      } else {
        this.setState({ isActiveCompetition: false });
      }
    });
  }
  componentDidMount() {
    if (this.props.hasNotification) {
      this.getAllNotifications();
      this.startInterval();
    }

    fetchUserAvatar().then(resp => {
      this.setState({ avatar: { ...resp.data } });
    });
  }
  componentWillUnmount() {
    clearInterval(this.notificaitonTimer);
  }
  togglePopupNotification = () => {
    this.setState({
      showPopupNotification: false
    });
  };
  handleBellClick = () => {
    this.setState({
      showSliderNotification: true
    });
    postNotificationsApi();
  };
  handleNavigateToStory = () => {
    const { description, storyId } = this.state.popupNotification;
    const draftStoryId = storyId === 0 ? "" : storyId;
    trackNotificationClick({ result: description, storyId: draftStoryId });
    if (storyId) {
      this.props.history.push(`/workspace/${storyId}`);
    } else {
      this.props.history.push("/select-type");
    }
    this.togglePopupNotification();
  };
  handleLibraryClick = () => {
    trackLibraryClick();
    this.props.history.push("/library");
  };
  handleLibraryTechClick = () => {
    trackLibraryClick();
    this.props.history.push({
      pathname: `/teacher-library`,
      search: "?query=tech"
    });
  };

  handleDashboardTechClick = () => {
    this.props.history.push("/teacher-dashboard");
  };

  handleDashboardClassTechClick = () => {
    this.props.history.push("/teacher-group");
  };

  handleCompetitionClick = () => {
    this.props.history.push("/competition-dashboard");
  };

  getAllNotifications = () => {
    const { showPopupNotification, showSliderNotification } = this.state;
    // Don't change any notification if someone is already viewing slider notification.
    if (!showSliderNotification || !showPopupNotification) {
      getNotificationsApi()
        .then(response => {
          const popupNotification = findLast(response, {
            location: "POPUP",
            read: false
          });

          const sliderNotifications = response
            .filter(item => item.location === "SLIDER")
            .map(item => ({
              ...item,
              emoji:
                item.emoji === "EMPTY" ? item.notificationTitle : item.emoji
            }));
          this.setState({
            notifications: response,
            popupNotification,
            sliderNotifications,
            showPopupNotification: !!popupNotification
          });
          if (
            popupNotification &&
            popupNotification.notificationTitle !== "APPLICATION_FEEDBACK"
          )
            patchNotificationsApi(popupNotification.id);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  startInterval = () => {
    this.notificaitonTimer = setInterval(() => {
      this.getAllNotifications();
    }, 30000);
  };

  handleCloseNotification = () => {
    const { sliderNotifications } = this.state;
    const notification = sliderNotifications.map(item => ({
      ...item,
      read: true
    }));

    this.setState({
      showSliderNotification: false,
      sliderNotifications: notification
    });
  };

  handleNavigateToProfile = () => {
    const { pathname } = this.props.location;
    if (pathname !== "/profile") {
      ActivityLogger.log(ActivityLogger.NAVIGATING_OWN_PROFILE);
      if (this.props.isTeacher) {
        this.props.history.push("/update-avatar");
      } else {
        this.props.history.push("/profile");
      }
    }
  };

  navigateToDashboard = () => {
    this.props.history.push(RouteNames.Default.route);
  };
  handleResetPassword = () => {
    this.setState({ passwordModal: true });
  };
  render() {
    const {
      showPopupNotification,
      showSliderNotification,
      popupNotification,
      sliderNotifications,
      passwordModal,
      avatar,
      isSideNavShow,
      isActiveCompetition
    } = this.state;

    const {
      children,
      hasNotification,
      roles,
      showAvatar,
      noBorder,
      headerColor,
      view,
      isTeacher,
      isModerator
    } = this.props;
    return (
      <header
        className="header"
        style={{
          border: noBorder ? "none" : "border-bottom: 1px solid black",
          backgroundColor: headerColor ? headerColor : "#fff"
        }}
      >
        {children}

        <HamburgerButton
          hide={view == "editAva"}
          onClick={() => {
            this.setState({ isSideNavShow: !isSideNavShow });
          }}
        />

        <div className="header-icons">
          {hasNotification && !isTeacher && (
            <Fragment>
              {/* <SearchButton onClick={() => console.log("search clicked")} /> */}
              {isActiveCompetition && (
                <CompetitionButton
                  onClick={this.handleCompetitionClick}
                  icon={view == "competition" ? CompOn : CompOff}
                />
              )}
              <LibraryButton
                onClick={this.handleLibraryClick}
                icon={view == "library" ? LibraryOn : LibraryOff}
              />
              <StudentMenu
                avatar=""
                onResetPassword={this.handleResetPassword}
                onProfileChange={this.handleNavigateToProfile}
              />

              <Header.Bell
                active={!!find(sliderNotifications, { read: false })}
                onClick={this.handleBellClick}
              />
              {showPopupNotification ? (
                popupNotification &&
                popupNotification.notificationTitle ===
                  "APPLICATION_FEEDBACK" ? (
                  <FeedbackModal
                    togglePopup={this.togglePopupNotification}
                    modalInfo={popupNotification}
                    modelColor={headerColor}
                  />
                ) : (
                  <ModalPopup
                    onClick={this.handleNavigateToStory}
                    modalInfo={popupNotification}
                    toggle={this.togglePopupNotification}
                    modelColor={headerColor}
                  />
                )
              ) : (
                ""
              )}
            </Fragment>
          )}
          {showAvatar && includes(roles, USER_ROLES.TEACHER) && (
            <>
              <MenuButton
                onClick={this.handleDashboardTechClick}
                icon={view == "teacher-dashboard" ? ClassesOn : ClassesOff}
              />

              <MenuButton
                onClick={this.handleDashboardClassTechClick}
                icon={view == "teacher-group" ? ClassIconOn : ClassIcon}
                customClass="classIcon"
              />
              <LibraryButton
                onClick={this.handleLibraryTechClick}
                icon={view == "library" ? LibraryOn : LibraryOff}
              />
              <TeacherMenu
                avatar=""
                onResetPassword={this.handleResetPassword}
              />
            </>
          )}
          {includes(roles, USER_ROLES.ADMIN) && <AdminMenu  />}
          {includes(roles, USER_ROLES.MODERATOR) && <ModeratorMenu />}
          <ResetPassword
            onClose={() => this.setState({ passwordModal: false })}
            passwordModal={passwordModal}
          />
        </div>

        {showSliderNotification && (
          <Notification
            notifications={sliderNotifications}
            onClose={this.handleCloseNotification}
            modelColor={headerColor}
          />
        )}

        {isSideNavShow && (
          <SideNav
            themeColor={headerColor ? headerColor : "#cfecbf"}
            view={view}
            avatar={avatar}
            onNotificationClick={this.handleBellClick}
            onClose={() => {
              this.setState({ isSideNavShow: false });
            }}
            onProfileChange={this.handleNavigateToProfile}
            onResetPassword={this.handleResetPassword}
            isActiveCompetition={isActiveCompetition}
            isTeacher={isTeacher}
          />
        )}
      </header>
    );
  }
}

const mapStateToProps = ({ user }) => {
  const { roles, avatar } = user;

  return {
    avatar,
    roles
  };
};
Header.defaultProps = {
  showAvatar: true
};
export default connect(
  mapStateToProps,
  { logout }
)(withRouter(Header));
