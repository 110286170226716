import { ActionTypes } from "../../action";
import { logout as logoutApi } from "./logoutApi";
import {logOnClick} from "../../services/activityLogs";

export const logout = () => dispatch => {
  logOnClick({result:"Logout clicked"});
  return logoutApi().then(() => {
    dispatch({
      type: ActionTypes.LOGOUT
    });
  });
};
