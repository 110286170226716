export const FeedbackList = [
  {
    id: 1,
    name: "Good description!"
  },
  {
    id: 2,
    name: "Good use of techniques!"
  },
  {
    id: 3,
    name: "Good range of sentences!"
  },
  {
    id: 4,
    name: "Good range of sentence openers!"
  },
  {
    id: 5,
    name: "Interesting character!"
  },
  {
    id: 6,
    name: "Great weather description!"
  },
  {
    id: 7,
    name: "Well-structured!"
  }
];
