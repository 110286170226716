import axios from "axios";

const currentComp = "/competition/getactive";
const currentCompDetail = "/competition/";
const finalResults = "/competition/winners";
const voteFor = "/competition/vote/";
const compById = "/competition/old/";
const latestComp = "competition/getlatest"

export const getCurrentComp = () => {
  return axios.get(currentComp).then(({ data }) => {
    return data;
  });
};

export const getCompetitionById = compId => {
  return axios.get(`${compById}${compId}`).then(({ data }) => {
    return data;
  });
};

export const getCurrentCompDetail = compId => {
  return axios.get(`${currentCompDetail}${compId}`).then(({ data }) => {
    return data;
  });
};

export const getFinalResults = () => {
  return axios.get(finalResults).then(({ data }) => {
    return data;
  });
};

export const voteForStory = storyId => {
  return axios.post(`${voteFor}${storyId}`).then(({ data }) => {
    return data;
  });
};

export const getCurrentCompNotValidDate = () => {
  return axios.get(currentComp).then(({ data }) => {
    let checkNotValid = false;
    if (data) {
      checkNotValid = Date.now() > data.data.votingStartDate;
    }

    return checkNotValid;
  });
};

export const getLatestComp = () => {
  return axios.get(latestComp).then(({ data }) => {
    return data;
  });
};
