const Top = [
  {
    id: 1,
    label: 'No Hair',
    name: 'NoHair',
    type: 'NONE',
  },
  {
    id: 2,
    label: 'Eye Patch',
    name: 'Eyepatch',
    type: 'NONE',
  },
  {
    id: 3,
    label: 'Hat',
    name: 'Hat',
    type: 'NONE',
  },
  {
    id: 4,
    label: 'Hijab',
    name: 'Hijab',
    type: 'HAT',
  },
  {
    id: 5,
    label: 'Turban',
    name: 'Turban',
    type: 'HAT',
  },
  {
    id: 6,
    label: 'Trucker hat',
    name: 'WinterHat1',
    type: 'HAT',
  },
  {
    id: 7,
    label: 'Winter hat with strings',
    name: 'WinterHat2',
    type: 'HAT',
  },
  {
    id: 8,
    label: 'Winter bobble hat',
    name: 'WinterHat3',
    type: 'HAT',
  },
  {
    id: 9,
    label: 'Winter bobble hat with cat ears',
    name: 'WinterHat4',
    type: 'HAT',
  },
  {
    id: 10,
    label: 'Long wavey hair',
    name: 'LongHairBigHair',
    type: 'HAIR',
  },
  {
    id: 11,
    label: 'Short bob haircut',
    name: 'LongHairBob',
    type: 'HAIR',
  },
  {
    id: 12,
    label: 'Top bun',
    name: 'LongHairBun',
    type: 'HAIR',
  },
  {
    id: 13,
    label: 'Medium curly hair',
    name: 'LongHairCurly',
    type: 'HAIR',
  },
  {
    id: 14,
    label: 'Long curly hair',
    name: 'LongHairCurvy',
    type: 'HAIR',
  },
  {
    id: 15,
    label: 'Long dreadlocks',
    name: 'LongHairDreads',
    type: 'HAIR',
  },
  {
    id: 16,
    label: 'Flower head band',
    name: 'LongHairFrida',
    type: 'NONE',
  },
  {
    id: 17,
    label: 'Afro',
    name: 'LongHairFro',
    type: 'HAIR',
  },
  {
    id: 18,
    label: 'Afro with headband',
    name: 'LongHairFroBand',
    type: 'HAIR',
  },
  {
    id: 19,
    label: 'Short straight hair with side fringe',
    name: 'LongHairNotTooLong',
    type: 'HAIR',
  },
  {
    id: 20,
    label: 'Long hair with shaved sides',
    name: 'LongHairShavedSides',
    type: 'NONE',
  },
  {
    id: 21,
    label: 'Medium straight hair with block fringe',
    name: 'LongHairMiaWallace',
    type: 'HAIR',
  },
  {
    id: 22,
    label: 'Long straight hair with side parting',
    name: 'LongHairStraight',
    type: 'HAIR',
  },
  {
    id: 23,
    label: 'Long hair with fringe behind ears',
    name: 'LongHairStraight2',
    type: 'HAIR',
  },
  {
    id: 24,
    label: 'Medium straight hair with fringe',
    name: 'LongHairStraightStrand',
    type: 'HAIR',
  },
  {
    id: 25,
    label: 'Short spiky hair',
    name: 'ShortHairDreads01',
    type: 'HAIR',
  },
  {
    id: 26,
    label: 'Short dreadlocks',
    name: 'ShortHairDreads02',
    type: 'HAIR',
  },
  {
    id: 27,
    label: 'Short mohawk hair',
    name: 'ShortHairFrizzle',
    type: 'HAIR',
  },
  {
    id: 28,
    label: 'Short hair mullet',
    name: 'ShortHairShaggyMullet',
    type: 'HAIR',
  },
  {
    id: 29,
    label: 'Short curly hair',
    name: 'ShortHairShortCurly',
    type: 'HAIR',
  },
  {
    id: 30,
    label: 'Short flat hair',
    name: 'ShortHairShortFlat',
    type: 'HAIR',
  },
  {
    id: 31,
    label: 'Flat round short hair',
    name: 'ShortHairShortRound',
    type: 'HAIR',
  },
  {
    id: 32,
    label: 'Short wavy hair',
    name: 'ShortHairShortWaved',
    type: 'HAIR',
  },
  {
    id: 33,
    label: 'Bald with hair at sides',
    name: 'ShortHairSides',
    type: 'HAIR',
  },
  {
    id: 34,
    label: 'Short bowl hair',
    name: 'ShortHairTheCaesar',
    type: 'HAIR',
  },
  {
    id: 35,
    label: 'Short bowl with side part',
    name: 'ShortHairTheCaesarSidePart',
    type: 'HAIR',
  },
];

export default Top;
