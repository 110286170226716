import React, { Component } from "react";
import PropTypes from "prop-types";
import { ReviewComments } from "../../common";
import { CommentList, EmojiList } from "../../utils";
import orderBy from "lodash/orderBy";
import { commentsSummary } from "../../views/Student/ClassmateWork/classmateWorkApi";

class CommentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentList: CommentList,
      emojiList: EmojiList,
      commentSummaryList: [],
      emojiSummaryList: [],
      ownComment: "",
      ownEmoji: ""
    };
  }

  componentDidMount() {
    const { storyId } = this.props;
    commentsSummary({ storyId }).then(response => {
      const updatedComments = orderBy(
        response.commentList
          .map(x =>
            Object.assign(x, CommentList.find(y => y.commentType == x.name))
          )
          .filter(item => item.name !== "EMPTY"),
        "count",
        "desc"
      );
      const updatedEmoji = EmojiList.map(x => {
        return {
          ...x,
          ...response.emojiList.find(y => y.name == x.emojiType)
        };
      });
      this.setState({
        commentSummaryList: updatedComments,
        emojiSummaryList: updatedEmoji,
        ownComment: response.ownComment,
        ownEmoji: response.ownEmoji
      });
    });
  }

  render() {
    const {
      emojiSummaryList,
      commentSummaryList,
      ownComment,
      ownEmoji
    } = this.state;

    const { children } = this.props;

    return (
      <ReviewComments>
        <ReviewComments.EmojisList>
          {emojiSummaryList.map(item => (
            <ReviewComments.EmojisItem
              key={item.id}
              emoji={item.emoji}
              isSelected={ownEmoji === item.emojiType}
            >
              {item.count ? item.count : 0}
            </ReviewComments.EmojisItem>
          ))}
        </ReviewComments.EmojisList>

        <ReviewComments.List>
          {commentSummaryList.map(item => (
            <ReviewComments.Item
              key={item.id}
              count={item.count}
              isSelected={ownComment === item.commentType}
            >
              {item.name}
            </ReviewComments.Item>
          ))}
        </ReviewComments.List>
        {children}
      </ReviewComments>
    );
  }
}

CommentSummary.propTypes = {
  storyId: PropTypes.number.isRequired
};

export default CommentSummary;
