import React from "react";
import "./hamburger-button.css";
import { Hamburger } from "../../assets/images";

const HamburgerButton = ({ hide, onClick }) => (
  <button className="hamburger-btn header-icon" onClick={onClick}>
    {!hide && (
      <img className="hamburger-image" src={Hamburger} alt="hamburger" />
    )}
  </button>
);

export { HamburgerButton };
