import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { disableFeedbackNotification } from "./feedbackModalApi";
import { patchNotificationsApi } from "../headerApi";
import { logSurveyClick } from "../../../services/activityLogs";
class FeedbackModal extends Component {
  handleDontShowAgainClick = () => {
    disableFeedbackNotification().then(data => {});
    this.toggleNotification();
  };
  handleSurveyClick = () => {
    disableFeedbackNotification().then(data => {
      logSurveyClick();
    });
    this.toggleNotification();
  };
  toggleNotification = () => {
    const { modalInfo, togglePopup } = this.props;
    patchNotificationsApi(modalInfo.id);
    togglePopup();
  };
  render() {
    const { modelColor } = this.props;

    return (
      <Modal
        className="modal-popup"
        isOpen={true}
        centered={true}
        toggle={this.toggleNotification}
      >
        <div style={{ backgroundColor: modelColor }}>
          <ModalHeader toggle={this.toggleNotification} />
          <ModalBody>
            <h3>We'd love your feedback!</h3>
            <p>
              Now that you've had some time to use Connected Creatives, we'd
              love to hear your thoughts!
            </p>
          </ModalBody>

          <ModalFooter className="vertically">
            <a
              onClick={this.handleSurveyClick}
              target="_blank"
              className="btn btn-primary"
              rel="noopener noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSffhMdQSSzFRPr66fwenUpVA8uWnwC7pZhs9Pp05K6bi8Hkjg/viewform"
            >
              Let's go!
            </a>
            <Button
              className="white-button"
              onClick={this.toggleNotification}
              style={{ color: modelColor }}
            >
              Not right now
            </Button>
            <Button
              className="white-button"
              onClick={this.handleDontShowAgainClick}
              style={{ color: modelColor }}
            >
              Don't show again
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    );
  }
}

export { FeedbackModal };
