const FacialHairTypes = [
  {
    id: 1,
    label: 'None',
    name: 'Blank',
  },
  {
    id: 2,
    label: 'Medium beard',
    name: 'BeardMedium',
  },
  {
    id: 3,
    label: 'Short beard',
    name: 'BeardLight',
  },
  {
    id: 4,
    label: 'Long beard',
    name: 'BeardMagestic',
  },
  {
    id: 5,
    label: 'French moustache',
    name: 'MoustacheFancy',
  },
  {
    id: 6,
    label: 'Normal moustache',
    name: 'MoustacheMagnum',
  },
];

export default FacialHairTypes;
