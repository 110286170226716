import React from "react";
import PropTypes from "prop-types";
import { Label, Input } from "reactstrap";

const Select = ({ name, id, onChange, className, label, children }) => {
  return (
    <React.Fragment>
      {label ? <Label for={name}>{label}</Label> : null}
      <Input
        type="select"
        name={name}
        id={id}
        onChange={onChange}
        className={className}
      >
        {children}
      </Input>
    </React.Fragment>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

export { Select };
