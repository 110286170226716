import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./modal.css";

class ModalPopup extends Component {
  render() {
    const { toggle, modalInfo, onClick, modelColor } = this.props;
    const { description, notificationTitle } = modalInfo;

    return (
      <Modal
        className="modal-popup"
        isOpen={true}
        centered={true}
        toggle={toggle}
      >
        <div style={{ backgroundColor: modelColor }}>
          <ModalHeader toggle={toggle} />
          <ModalBody>
            <h3>{notificationTitle} </h3>
            <p>{description}</p>
          </ModalBody>
          {notificationTitle === "Wow!" ? (
            <br />
          ) : (
            <ModalFooter>
              <Button
                color="primary"
                onClick={onClick}
                style={{ color: modelColor }}
              >
                {notificationTitle === "You're nearly there!"
                  ? "Let's go!"
                  : "Create"}
              </Button>
            </ModalFooter>
          )}
        </div>
      </Modal>
    );
  }
}

export { ModalPopup };
