import React, { Component } from "react";
import "./iconcard.css";
class IconCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: props.path,
      selectState: false
    };
  }

  componentDidMount() {
    const { path, selected } = this.props;

    this.setState({
      selectState: selected,
      currentImage: selected === true ? `hover/${path}` : path
    });
  }
  onClick = param => {
    const { selectState } = this.state;
    const { path } = this.props;
    this.setState({
      selectState: !selectState,
      currentImage: !selectState ? `hover/${path}` : path
    });
    this.props.onClick(param);
  };

  render() {
    const { path, name, id } = this.props;
    const { currentImage, selectState } = this.state;

    return (
      <div
        className={`icon-card`}
        onClick={() => {
          this.onClick(id);
        }}
        onPointerEnter={() =>
          selectState ? null : this.setState({ currentImage: `hover/${path}` })
        }
        onPointerLeave={() =>
          selectState ? null : this.setState({ currentImage: `${path}` })
        }
        key={id}
      >
        <div className="icon-card-img-cnt">
          <img
            className="icon-card-image"
            alt={name}
            src={require(`../../../assets/images/genreicons/${currentImage}`)}
          />
        </div>
        <div className="icon-card-text-cnt">
          <p className="icon-card-text">{name}</p>
        </div>
      </div>
    );
  }
}

export default IconCard;
