export const EmptyFile = new Blob([""], {type : ""});
export const EmptyAvatar = {
  hasAvatar: false,
  accessoriesType: null,
  clotheColor: null,
  clotheType: null,
  eyeType: null,
  eyebrowType: null,
  facialHairColor: null,
  facialHairType: null,
  hairColor: null,
  mouthType: null,
  skinColor: null,
  topType: null
};

export const tableOptions = {
  sizePerPage: 5,
  sizePerPageList: [
    {
      text: "5",
      value: 5
    },
    {
      text: "10",
      value: 10
    },
    {
      text: "20",
      value: 20
    },
    {
      text: "50",
      value: 50
    }
  ]
};
