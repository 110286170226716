import React, { Component, Fragment, memo } from "react";
import PropTypes from "prop-types";
import ViewIcon from "../../assets/images/views.svg";
import HappyIcon from "../../assets/images/emojis.svg";
import FeedbackIcon from "../../assets/images/comments.svg";
import "./story.css";
import { Icons as Genres } from "../../views/Student/GenreTagging/Icons";
import find from "lodash/find";
import { LineEllipsis } from "../LineEllipsis/LineEllipsis";
import { CompOn } from "../../assets/images";

const Title = ({ title }) => <h4 className="story-title ellipsis">{title}</h4>;
const Body = ({ children, status }) => (
  <div className={`story-body ${status === "Published" ? "" : "story-draft"}`}>
    {children}
  </div>
);
const Image = ({ src, showPos }) => (
  <div className="story-image-wrapper">
    <img
      className={`story-image ${showPos ? "story-image-winner" : ""}`}
      src={src}
      alt="story_image"
    />
  </div>
);
const Description = ({ className, detail, lines }) => (
  <h5 className={"story-description " + className}>
    <LineEllipsis text={detail} lines={lines || 3} />
  </h5>
);
const Statistics = ({ stats }) => (
  <div className="story-statistics">
    <Views view={stats.totalViews} />
    <Reactions emojis={stats.emoji} reactions={stats.totalReactions} />
    <FeedbackCount feedbackCounts={stats.totalFeedbacks} />
  </div>
);
const Views = ({ view }) => (
  <div className="story-views story-stat">
    <img className="view-icon" src={ViewIcon} alt="view-icon" />
    <span className="v-middle">{view}</span>
  </div>
);

const Reactions = ({ reactions }) => (
  <div className="story-views story-stat">
    <img className="view-icon" src={HappyIcon} alt="happy-icon" />
    <span className="v-middle">{reactions}</span>
  </div>
);
const FeedbackCount = ({ feedbackCounts }) => (
  <div className="story-fb-count story-stat">
    <img className="feedback-icon" src={FeedbackIcon} alt="feedback-icon" />
    <span className="v-middle">{feedbackCounts}</span>
  </div>
);
const Column = ({ children, image }) => (
  <div className={`story-column ${image ? "column-image" : "column-default"}`}>
    {children}
  </div>
);

const Column2 = ({ children, image }) => (
  <div className={`story-column ${image ? "column-image" : "column-default2"}`}>
    {children}
  </div>
);

const Row = ({ children, flex, showPos }) => (
  <div
    className={`story-row ${flex ? "story-row-flex" : ""} ${
      showPos ? "winnersStoryFlex" : ""
    }`}
  >
    {children}
  </div>
);
const Picture = ({ story, showPos }) => {
  return (
    <Fragment>
      {story.storyImage ? (
        <Story.Image src={story.storyImage} showPos={showPos} />
      ) : story.genres && story.genres.length ? (
        <StoryGenre genres={story.genres} showPos={showPos} />
      ) : (
        ""
      )}
    </Fragment>
  );
};

const Badge = () => {
  return (
    <Fragment>
      <img className="badge-image" src={CompOn} alt="library" />
    </Fragment>
  );
};

const StoryGenre = memo(({ genres, showPos }) => {
  if (genres && genres.length > 0) {
    let randomGenres = Math.floor(Math.random() * genres.length);
    const genreObj = find(Genres, genre => genre.key === genres[randomGenres]);
    return (
      <div className="story-image-wrapper">
        <img
          className={`story-image ${showPos ? "story-image-winner" : ""}`}
          alt="story-pic"
          src={require(`../../assets/images/genreicons/hover/${genreObj.path}`)}
        />
      </div>
    );
  }
  return null;
});

class Story extends Component {
  static Title = Title;
  static Body = Body;
  static Image = Image;
  static Column = Column;
  static Column2 = Column2;
  static Description = Description;
  static Statistics = Statistics;
  static Row = Row;
  static Picture = Picture;
  static Badge = Badge;

  render() {
    const { children, onClick, showPos } = this.props;
    return (
      <div
        className={`story ${showPos ? "storyWinner" : ""}`}
        onClick={onClick}
        {...this.props}
      >
        {children}
      </div>
    );
  }
}

Story.propTypes = {
  children: PropTypes.node.isRequired
};
Story.Description.defaultProps = {
  className: ""
};
export { Story };
