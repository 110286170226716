import React, { Component, Fragment } from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ProfileIcon } from "../ProfileIcon/ProfileIcon";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../LogoutButton/logoutAction";
import { RouteNames } from "../../../Routes/RouteNames";

class StudentMenu extends Component {
  state = {
    dropdownOpen: false,
    btnDropright: false,
  };
  navigateToDashboard = () => {
    const { pathname } = this.props.location;
    if (pathname !== RouteNames.StudentDashboard.route)
      this.props.history.push(RouteNames.Default.route);
  };

  normalFlowPdf = () => {};

  render() {
    const { avatar, onResetPassword, onProfileChange } = this.props;
    return (
      <Fragment>
        <ButtonDropdown
          className="dropdown-wrapper header-icon"
          isOpen={this.state.dropdownOpen}
          toggle={() =>
            this.setState({ dropdownOpen: !this.state.dropdownOpen })
          }
        >
          <DropdownToggle className="btn-secondary-bg-none">
            <ProfileIcon
              avatar={avatar}
              hover
              dropdownOpen={this.state.dropdownOpen}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-align">
            <DropdownItem
              onClick={onProfileChange}
              className="dropdown-item-setting"
            >
              My Profile
            </DropdownItem>
            <DropdownItem
              onClick={() => this.navigateToDashboard()}
              className="dropdown-item-setting"
            >
              Dashboard
            </DropdownItem>
            <DropdownItem
              onClick={onResetPassword}
              className="dropdown-item-setting"
            >
              Reset Password
            </DropdownItem>
            <ButtonDropdown
              direction="left"
              className="dropdown-item-setting"
              isOpen={this.state.btnDropright}
              onMouseLeave={() => {
                this.setState({ btnDropright: false });
              }}
              onMouseEnter={() => {
                this.setState({ btnDropright: !this.state.btnDropright });
              }}
            >
              <DropdownToggle className="dropdown-item-setting">
                Help Menu
              </DropdownToggle>
              <DropdownMenu className="dropdown-item-setting">
                <a
                  href="  https://app.connectedcreatives.co.uk/cdn/help/Myself-Flow.pdf"
                  download
                >
                  <DropdownItem className="dropdown-item-setting">
                    Normal Flow
                  </DropdownItem>
                </a>
                <a
                  href="https://app.connectedcreatives.co.uk/cdn/help/Student-Comp-Flow-new.pdf"
                  download
                >
                  <DropdownItem className="dropdown-item-setting">
                    Competition Flow
                  </DropdownItem>
                </a>
              </DropdownMenu>
            </ButtonDropdown>

            <DropdownItem
              onClick={this.props.logout}
              className="dropdown-item-setting"
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </Fragment>
    );
  }
}

export default connect(null, { logout })(withRouter(StudentMenu));
