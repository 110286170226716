const EyebrowTypes = [
  {
    id: 1,
    label: 'Angry',
    name: 'Angry',
  },
  {
    id: 2,
    label: 'Very angry',
    name: 'AngryNatural',
  },
  {
    id: 3,
    label: 'Default',
    name: 'Default',
  },
  {
    id: 4,
    label: 'Natural',
    name: 'DefaultNatural',
  },
  {
    id: 5,
    label: 'Flat',
    name: 'FlatNatural',
  },
  {
    id: 6,
    label: 'Raised',
    name: 'RaisedExcited',
  },
  {
    id: 7,
    label: 'Excited',
    name: 'RaisedExcitedNatural',
  },
  {
    id: 8,
    label: 'Concerned',
    name: 'SadConcerned',
  },
  {
    id: 9,
    label: 'Sad',
    name: 'SadConcernedNatural',
  },
  {
    id: 10,
    label: 'Unibrow',
    name: 'UnibrowNatural',
  },
  {
    id: 11,
    label: 'One up one down',
    name: 'UpDown',
  },
  {
    id: 12,
    label: 'Extreme one up one down',
    name: 'UpDownNatural',
  },
];

export default EyebrowTypes;
