import React, { Component } from "react";
import { Button } from "reactstrap";
import "./reviewcomments.css";

const Item = ({ children, count, isSelected }) => (
  <div className={`review-comments-item ${isSelected ? "selected" : ""}`}>
    <span className="comments-name">{children}</span>
    <span className="comments-count">{count}</span>
  </div>
);
const List = ({ children, isSelected }) => (
  <div className={`review-comments-list ${isSelected ? "selected" : ""}`}>
    {children}
  </div>
);
const BackButton = ({ children, onClick }) => (
  <Button className="round-button w-100 back-button" onClick={onClick}>
    {children}
  </Button>
);
const EmojisList = ({ children, isSelected }) => (
  <div className={`emojis-list ${isSelected ? "selected" : ""}`}>
    {children}
  </div>
);
const EmojisItem = ({ children, emoji, isSelected }) => (
  <div className={`emojis-item emoji-${emoji} ${isSelected ? "selected" : ""}`}>
    <div className="emoji-count"> {children}</div>
  </div>
);
class ReviewComments extends Component {
  static Item = Item;
  static List = List;
  static BackButton = BackButton;
  static EmojisItem = EmojisItem;
  static EmojisList = EmojisList;
  render() {
    const { children } = this.props;
    return (
      <div className="review-comments-overlay">
        <div className="review-comments">{children}</div>
      </div>
    );
  }
}

export { ReviewComments };
