import React, { Component } from "react";
import { Button } from "reactstrap";
import "./feedback.css";
import Comment from "../../assets/images/comment.png";
import Publish from "../../assets/images/publish.svg";
const Title = ({ children }) => <p className="title">{children}</p>;
const Body = ({ children }) => (
  <div className="body">
    <p>{children}</p>
  </div>
);
const CommentImg = () => (
  <span className="comment-img" src={Comment} alt="comment" />
);
const PublishImg = () => (
  <span className="publish-img" src={Publish} alt="publish" />
);
const Status = ({ status }) => {
  return status === "PUBLISHED" || status === "APPROVED" ? (
    <PublishImg />
  ) : (
    <CommentImg />
  );
};
const PersonalFeedbackStatus = ({ children }) => (
  <h4 className="comment-img-personal">{children}</h4>
);
const Group = ({ children }) => (
  <div className="feedback-group">{children}</div>
);
const Action = ({ onAcceptClick, onRejectClick }) => (
  <div className="feedback-action">
    <Button
      onClick={onRejectClick}
      className="round-button white-button decline-feedback"
    >
      Deny
    </Button>
    <Button onClick={onAcceptClick} className="round-button accept-feedback">
      Approve
    </Button>
  </div>
);
const Row = ({ children }) => <div className="feedback-row">{children}</div>;
class Feedback extends Component {
  static Title = Title;
  static Body = Body;
  static CommentImg = CommentImg;
  static PublishImg = PublishImg;
  static Status = Status;
  static Group = Group;
  static Row = Row;
  static Action = Action;
  static PersonalFeedbackStatus = PersonalFeedbackStatus;
  render() {
    const { children, indecator } = this.props;

    return (
      <div
        className="feedback"
        style={{
          background: indecator,
          borderTop: indecator ? "1px solid #000" : "none"
        }}
      >
        <div className="feedback-center">
          <div className="wrapper">{children}</div>
        </div>
      </div>
    );
  }
}

export { Feedback };
