import React, { Component } from "react";
import "./loading-wrapper.css";
import { Loading } from "../Loading/Loading";

let responseTime;
class LoadingWrapper extends Component {
  state = {
    isLoading: false
  };
  constructor(props) {
    super(props);

    props.isLoading ? this.startLoading() : this.stopLoading();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isLoading !== prevProps.isLoading) {
      this.props.isLoading ? this.startLoading() : this.stopLoading();
    }
  }
  componentDidMount() {}
  startLoading = () => {
    responseTime = setTimeout(() => {
      this.setState({ isLoading: true });
    }, 500);
  };

  stopLoading = () => {
    clearTimeout(responseTime);
    this.setState({ isLoading: false });
  };

  render() {
    const { children, className } = this.props;
    const { isLoading } = this.state;
    return (
      <div className={`loading-wrapper ${className}`}>
        {isLoading && <Loading />}
        {children}
      </div>
    );
  }
}

export { LoadingWrapper };
