import React from "react";
import loader from "../../assets/images/loader.gif";
import "./loading.css";
const Loading = () => (
  <div className="loading">
    <img className="loader-image" src={loader} alt="loading..." />
  </div>
);

export { Loading };
