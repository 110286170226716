import { ActionTypes } from "../../action";
import { authenticate as authenticateApi } from "./accountApi";
import { StorageService } from "../../services";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";

export const authenticate = params => dispatch => {
  return authenticateApi(params).then(data => {
    const { token } = data;

    const user = {
      firstName: data.firstName,
      lastName: data.lastName,
      userId: data.userId,
      roles: data.roles,
      displayPic: data.displayPic,
      avatar: {
        hasAvatar: !some(data.avatar, isEmpty),
        ...data.avatar
      },
    };
    StorageService.saveToken({ token, user });

    dispatch({
      type: ActionTypes.LOGIN_SUCCESS,
      payload: user
    });

    return data;
  });
};

export const userInformation = ({ user }) => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    payload: user
  };
};
