import React from "react";
import { Button } from "reactstrap";
import { logout } from "./logoutAction";
import { connect } from "react-redux";
import "./logoutbutton.css";
const LogoutButton = ({ logout }) => (
  <div className="logout-button">
    <Button onClick={logout} className="w-100">
      Logout
    </Button>
  </div>
);

export default connect(
  null,
  { logout }
)(LogoutButton);
