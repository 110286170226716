import React from "react";
import "./competitionButton.css";

const CompetitionButton = ({ onClick, icon }) => (
  <button className="libray-btn header-icon" onClick={onClick}>
    <img className="library-image" src={icon} alt="library" />
  </button>
);

export { CompetitionButton };
