import React from "react";
import "./notification.css";
import { NotificationOff, NotificationOn } from "../../../assets/images";

const NotificationBell = ({ onClick, active }) => (
  <button
    className={`notification-btn animated header-icon ${active ? "swing" : ""}`}
    onClick={onClick}
  >
    <img
      className="notification-image"
      src={active ? NotificationOn : NotificationOff}
      alt="notification"
    />
  </button>
);

export { NotificationBell };
