import { Component } from "react";
import withRouter from "react-router-dom/withRouter";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    // If the route has changed, scroll to top.

    if (this.props.location !== prevProps.location) {
      if (this.props.location.pathname.split("/")[1] == "classmate-work") {
        window.location.reload();
      } else {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
