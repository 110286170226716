import { lazy } from "react";

import { withRouter } from "react-router-dom";

//export * from "./Student/Library/Library";
export const Library = withRouter(
  lazy(() => import("./Student/Library/Library"))
);

//export { default as TaskSubmitted } from "./commonView/TaskSubmitted/TaskSubmitted";
export const TaskSubmitted = withRouter(
  lazy(() => import("./commonView/TaskSubmitted/TaskSubmitted"))
);

//export * from "./Accounts";
export { default as Login } from "./Accounts/Login/Login";
export const SchoolRegister = withRouter(
  lazy(() => import("./Accounts/SchoolRegister/SchoolRegister"))
);
//export const Login = withRouter(lazy(() => import('./Accounts/Login/Login')));
export const Register = withRouter(
  lazy(() => import("./Accounts/Register/Register"))
);
//export { default as AdminDashboard } from "./Administrator/AdminDashboard";
export const AdminDashboard = withRouter(
  lazy(() => import("./Administrator/AdminDashboard"))
);
// export {   default as AvatarChange } from "./commonView/AvatarChange/AvatarChange";
export const AvatarChange = withRouter(
  lazy(() => import("./commonView/AvatarChange/AvatarChange"))
);
/*export {  default as ClassmateWork} from "./Student/ClassmateWork/ClassmateWorks";*/
export const ClassmateWork = withRouter(
  lazy(() => import("./Student/ClassmateWork/ClassmateWorks"))
);
//export { default as GenreTagging } from "./Student/GenreTagging/GenreTagging";
export const GenreTagging = withRouter(
  lazy(() => import("./Student/GenreTagging/GenreTagging"))
);

//export {  default as PagenotFound} from "./commonView/PagenotFound/PagenotFound";
export const PagenotFound = withRouter(
  lazy(() => import("./commonView/PagenotFound/PagenotFound"))
);
//export { default as ProfilePage } from "./Student/ProfilePage/ProfilePage";
export const ProfilePage = withRouter(
  lazy(() => import("./Student/ProfilePage/ProfilePage"))
);
//export * from "./Student/SelectType/StoryTypeCategory";
export const StoryTypeCategory = withRouter(
  lazy(() => import("./Student/SelectType/StoryTypeCategory"))
);
//export { default as StudentDashboard } from "./Student/Dashboard/Dashboard";
export const StudentDashboard = withRouter(
  lazy(() => import("./Student/Dashboard/Dashboard"))
);
//export {  default as TeacherDashboard} from "./Teacher/TeacherDashboard/TeacherDashboard";
export const TeacherGroupDashboard = withRouter(
  lazy(() => import("./Teacher/TeacherDashboard/TeacherDashboard"))
);

export const TeacherModerator = withRouter(
  lazy(() => import("./Teacher/TeacherDashboard/TeacherModerator"))
);
//export {  default as TeacherDashboard} from "./Teacher/TeacherDashboard/TeacherDashboard";
export const TeacherDashboard = withRouter(
  lazy(() => import("./Teacher/TeacherMainDashboard/TeacherMainDashboard"))
);

// export {   default as TeacherRegister } from "./Accounts/TeacherRegister/TeacherRegister";
export const TeacherRegister = withRouter(
  lazy(() => import("./Accounts/TeacherRegister/TeacherRegister"))
);

export const TeacherSufixx = withRouter(
  lazy(() => import("./Accounts/TeacherSufixx/TeacherSuffix"))
);
//export * from "./Teacher/TeacherReview/TeacherReview";
export const TeacherReview = withRouter(
  lazy(() => import("./Teacher/TeacherReview/TeacherReview"))
);
//export {  default as TeacherViewStory} from "./Teacher/TeacherViewStory/TeacherViewStory";
export const TeacherViewStory = withRouter(
  lazy(() => import("./Teacher/TeacherViewStory/TeacherViewStory"))
);
//export { default as Unsubscribe } from "./Teacher/Unsubscribe/Unsubscribe";
export const Unsubscribe = withRouter(
  lazy(() => import("./Teacher/Unsubscribe/Unsubscribe"))
);
//export {  default as UploadFrontCover} from "./Student/UploadFrontCover/UploadFrontCover";
export const UploadFrontCover = withRouter(
  lazy(() => import("./Student/UploadFrontCover/UploadFrontCover"))
);
//export { default as Welcome } from "./commonView/Welcome/Welcome";
export const Welcome = withRouter(
  lazy(() => import("./commonView/Welcome/Welcome"))
);
//export { default as Workspace } from "./Student/Workspace/Workspace";
export const Workspace = withRouter(
  lazy(() => import("./Student/Workspace/Workspace"))
);

export const StorySummary = withRouter(
  lazy(() => import("./Student/Workspace/Story/StorySummary"))
);

export const CompDashboard = withRouter(
  lazy(() => import("./Competition/CompDashboard/CompDashboard"))
);

export const CompResults = withRouter(
  lazy(() => import("./Competition/CompResults/CompResults"))
);

export const ForgotPassword = withRouter(
  lazy(() => import("./Accounts/ForgotPassword/ForgotPassword"))
);

export const ResetPassword = withRouter(
  lazy(() => import("./Accounts/ResetPassword/ResetPassword"))
);
