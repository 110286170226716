import axios from "axios";

const URL = "/activitylog/profile";

export class ActivityLogger {
  static NAVIGATING_OWN_PROFILE =
    "On any page, user navigates to their own profile";

  static NAVIGATING_PROFILE_CENTER =
    "User navigates to another student's profile by selecting the centre username";
  static NAVIGATING_PROFILE_WRITTEN_BY =
    "User navigates to another student's profile by selecting the written by username";

  static NAVIGATING_OWN_STORY =
    "On own profile, user navigates to a published story";
  static VIEWING_OWN_FOLLOWER_LIST =
    "On own profile, user selects to view followers list";
  static SELECTING_OWN_FOLLOWER =
    "On own profile followers list, user selects a user";
  static VIEWING_OWN_FOLLOWING_LIST =
    "On own profile, user selects to view following list";
  static SELECTING_OWN_FOLLOWING =
    "On own profile following list, user selects a user";

  static VIEWING_OTHER_FOLLOWER_LIST =
    "On another student's profile, user selects to view followers list";
  static SELECTING_OTHER_FOLLOWER =
    "On another student's profile followers list, user selects a user";
  static NAVIGATING_OTHER_STORY =
    "On another student's profile, user navigates to a published story";
  static VIEWING_OTHER_FOLLOWING_LIST =
    "On another student's profile, user selects to view following list";
  static SELECTING_OTHER_FOLLOWING =
    "On another student's profile following list, user selects a user";

  static log(detail, storyId, otherUserId) {
    return axios.put(URL, { detail, storyId, otherUserId }).then(({ data }) => {
      return data;
    });
  }
}
