import React, { Component } from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../LogoutButton/logoutAction";
import { ProfileIcon } from "../ProfileIcon/ProfileIcon";

class ModeratorMenu extends Component {
  state = {
    dropdownOpen: false
  };


  render() {
    const { avatar, } = this.props;
    return (
      <div className="teacher-profile-btn">
        <ButtonDropdown
          className="dropdown-wrapper"
          isOpen={this.state.dropdownOpen}
          toggle={() =>
            this.setState({ dropdownOpen: !this.state.dropdownOpen })
          }
        >
          <DropdownToggle className="btn-secondary-bg-none">
            <ProfileIcon avatar={avatar} hover />
          </DropdownToggle>
          <DropdownMenu className="dropdown-align-teacher">
            <DropdownItem
              onClick={this.props.logout}
              className="dropdown-item-setting"
            >
              Logout
            </DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

export default connect(
  null,
  { logout }
)(withRouter(ModeratorMenu));
