const Icons = [
  {
    id: 1,
    path: "scifi.svg",
    hoverpath: "scifi.svg",
    name: "Science Fiction",
    key: "SCIENCE_FICTION"
  },
  {
    id: 2,
    path: "drama.svg",
    hoverpath: "drama.svg",
    name: "Drama",
    key: "DRAMA"
  },
  {
    id: 3,
    path: "actionadventure.svg",
    hoverpath: "actionadventure.svg",
    name: "Action / Adventure",
    key: "ACTION_ADVENTURE"
  },
  {
    id: 4,
    path: "romance.svg",
    hoverpath: "romance.svg",
    name: "Romance",
    key: "ROMANCE"
  },
  {
    id: 5,
    path: "comedy.svg",
    hoverpath: "comedy.svg",
    name: "Comedy",
    key: "COMEDY"
  },
  {
    id: 6,
    path: "mystery.svg",
    hoverpath: "mystery.svg",
    name: "Mystery",
    key: "MYSTERY"
  },
  {
    id: 7,
    path: "horror.svg",
    hoverpath: "horror.svg",
    name: "Horror",
    key: "HORROR"

  },
  {
    id: 8,
    path: "religious.svg",
    hoverpath: "religious.svg",
    name: "Religious / Spirituality",
    key: "RELIGIOUS_SPIRITUAL"
  },
  {
    id: 9,
    path: "history.svg",
    hoverpath: "history.svg",
    name: "History",
    key: "HISTORY"
  },
  {
    id: 10,
    path: "fantasy.svg",
    hoverpath: "fantasy.svg",
    name: "Fantasy",
    key: "FANTASY"
  },
  {
    id: 11,
    path: "thriller.svg",
    hoverpath: "thriller.svg",
    name: "Thriller",
    key: "THRILLER"
  },
  {
    id: 12,
    path: "fairytale.svg",
    hoverpath: "fairytale.svg",
    name: "Fairytale",
    key: "FAIRYTALE"
  },
  {
    id: 13,
    path: "crime.svg",
    hoverpath: "crime.svg",
    name: "Crime",
    key: "CRIME"
  },
  {
    id: 14,
    path: "friendship.svg",
    hoverpath: "friendship.svg",
    name: "Friendships & Relationships",
    key: "FRIENDSHIP_RELATIONSHIP"
  },
  {
    id: 15,
    path: "reviews.svg",
    hoverpath: "reviews.svg",
    name: "Reviews",
    key: "REVIEWS"
  },
  {
    id: 16,
    path: "culturallife.svg",
    hoverpath: "culturallife.svg",
    name: "Cultural life",
    key: "CULTURAL_LIFE"
  },
  {
    id: 17,
    path: "holidays.svg",
    hoverpath: "holidays.svg",
    name: "Holidays",
    key: "HOLIDAYS"
  },
  {
    id: 18,
    path: "tech.svg",
    hoverpath: "tech.svg",
    name: "Tech",
    key: "TECH"
  },
  {
    id: 19,
    path: "futureaspirations.svg",
    hoverpath: "futureaspirations.svg",
    name: "Future Aspirations",
    key: "FUTURE_ASPIRATIONS"
  },
];

export { Icons };
