import axios from "axios";

const GET_CLASSMATE_STORY = ({ storyId }) => `/story/${storyId}`;
const SAVE_COMMENT = ({ storyId }) => `/story/${storyId}/comment`;
const COMMENTS_SUMMARY = ({ storyId }) => `/story/comment-summary/${storyId}`;
const ENTER_CLASSMATE_STORY = ({ storyId }) =>
  `/activitylog/enter_other/${storyId}`;
const UPDATE_TIMER_EVENT = ({ activityLogId }) =>
  `/activitylog/${activityLogId}`;
const PERSONAL_FEEDBACK = `/personal-feedback`;
const GET_PERSONAL_FEEDBACK = ({ StoryId }) => `/personal-feedback/${StoryId}`;

const GET_FOLLOW = ({ userId }) => `/user/following/${userId}`;

export const saveComment = ({ commentType, emojiType, storyId }) => {
  return axios
    .post(SAVE_COMMENT({ storyId }), {
      commentType,
      emojiType
    })
    .then(({ data }) => data);
};
export const personalFeedbackApi = (storyId, text) => {
  return axios
    .post(PERSONAL_FEEDBACK, { feedbackText: text, storyId })
    .then(({ data }) => {
      return data;
    });
};

export const commentsSummary = ({ storyId }) => {
  return axios.get(COMMENTS_SUMMARY({ storyId })).then(({ data }) => {
    return data;
  });
};

export const getFollowApi = ({ userId }) => {
  return axios.get(GET_FOLLOW({ userId })).then(({ data }) => {
    return data;
  });
};

export const getClassmateStoryApi = ({ storyId }) => {
  return axios.get(GET_CLASSMATE_STORY({ storyId })).then(({ data }) => {
    return data;
  });
};

export const enterClassmateStoryEvent = ({ storyId }) => {
  return axios.post(ENTER_CLASSMATE_STORY({ storyId })).then(({ data }) => {
    return data;
  });
};

export const updateClassmateStoryTimer = ({ activityLogId }) => {
  return axios.post(UPDATE_TIMER_EVENT({ activityLogId })).then(({ data }) => {
    return data;
  });
};
export const getPersonalFeedbacksApi = ({ StoryId }) => {
  return axios.get(GET_PERSONAL_FEEDBACK({ StoryId })).then(({ data }) => data);
};
