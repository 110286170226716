import { StorageService } from ".";
import axios from "axios";
import { store } from "../App";
import { expireToken } from "../action";
import { grayLog, consoleGrayLog } from "./grayLog";
const BASE_URL = "/api";



// Add a response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch(expireToken());
    }

    if (error.response && error.response.status !== 200 && error.config.url.indexOf('graylog') === -1) {
      grayLog(error);
    }
    return Promise.reject(error);


  }
);

export class AuthService {
  static setDefaultAuthHeaders = () => {
    axios.defaults.headers.common["X-Auth-Token"] = StorageService.getToken();

  };

  static setDefaults = () => {
    axios.defaults.baseURL = BASE_URL;
    consoleGrayLog();
    AuthService.setDefaultAuthHeaders();
  };

  static isLoggedIn = () => StorageService.tokenExists();

  static clearToken = () => StorageService.clearToken();

  static verifyToken = () => {
    if (!this.isLoggedIn()) return Promise.resolve(false);
  };
}
