import React from "react";
import "./libraryButton.css";

const LibraryButton = ({ onClick, icon }) => (
  <button className="libray-btn header-icon" onClick={onClick}>
    <img className="library-image" src={icon} alt="library" />
  </button>
);

export { LibraryButton };
