import React, { Component } from "react";
import { Button } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import "./graphic-novel-reading.css";
import { StoryReading, StoryTitleGenres } from "../StoryReading/StoryReading";
import compact from "lodash/compact";
import { Feedback } from "../Feedback/Feedback";
class GraphicNovelReading extends Component {
  state = {
    currentSlide: 0,
    story: this.props.story
  };
  moveToNextSlide = () => {
    const { currentSlide } = this.state;
    this.setState({
      currentSlide: currentSlide + 1
    });
  };
  moveToPreviousSlide = () => {
    const { currentSlide } = this.state;
    this.setState({
      currentSlide: currentSlide - 1
    });
  };

  componentDidMount() {
    this.updateStory();
  }
  updateStory = () => {
    const { storyImage, graphicNovelImages } = this.props.story;
    const newGraphicNovelImages = [storyImage, ...graphicNovelImages];

    this.setState({
      story: {
        ...this.props.story,
        graphicNovelImages: compact(newGraphicNovelImages)
      }
    });
  };

  render() {
    const { currentSlide, story } = this.state;
    const {
      title,
      graphicNovelImages,
      userName,
      genres,
      status,
      date,
      blurb,
      feedback
    } = story;
    const isPublished = status === "PUBLISHED";
    const submitedDate = moment(date).format("Do MMM YYYY");
    return (
      <div className="graphic-novel-reading">
        <div className="story-upload-wrapper">
          {feedback &&
            feedback.map((item, index) => (
              <Feedback key={item.feedbackId}>
                <Feedback.Row>
                  <Feedback.Group>
                    <Feedback.Title>Your teacher wrote:</Feedback.Title>
                    <Feedback.Body>{item.feedbackText}</Feedback.Body>
                  </Feedback.Group>
                </Feedback.Row>
                <Feedback.Status
                  status={index > 0 ? "SUBMITTED" : item.feedBackStatus}
                />
              </Feedback>
            ))}
          <div className="story-wrapper">
            <StoryReading>
              <StoryReading.Heading>
                {genres && genres.length > 0 && (
                  <StoryTitleGenres genres={genres} />
                )}
                <StoryReading.Title>{title}</StoryReading.Title>
                <StoryReading.Blurb>{blurb}</StoryReading.Blurb>
              </StoryReading.Heading>
              {userName && (
                <StoryReading.SubTitle>
                  Written by&nbsp;
                  <b>{userName}</b>
                </StoryReading.SubTitle>
              )}
              {status && (
                <StoryReading.SubTitle>
                  {isPublished ? "Published" : "Submitted"} on&nbsp;
                  <b>{submitedDate}</b>
                </StoryReading.SubTitle>
              )}
            </StoryReading>
          </div>
        </div>
        <div className="upload-cover-content">
          <div className="carasol-wrapper">
            <Carousel
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              selectedItem={currentSlide}
              onChange={value => {
                this.setState({ currentSlide: value });
              }}
            >
              {graphicNovelImages &&
                graphicNovelImages.map((item, index) => (
                  <div key={index} className="upload-cover-box">
                    <div className="crop-image-box">
                      <img
                        className="cropped-img-box"
                        src={item}
                        alt="graphic novel pic"
                      />
                    </div>
                  </div>
                ))}
            </Carousel>

            {currentSlide > 0 && (
              <Button
                onClick={this.moveToPreviousSlide}
                className="previous-slide"
              />
            )}
            {graphicNovelImages &&
              currentSlide < graphicNovelImages.length - 1 && (
                <Button onClick={this.moveToNextSlide} className="next-slide" />
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default GraphicNovelReading;
