import React, { Component } from "react";
import PropTypes from "prop-types";
import "./topic.css";

const Title = ({ children }) => <h2 className="title">{children}</h2>;
const Body = ({ children }) => <div className="body">{children}</div>;
class Topic extends Component {
  static Title = Title;
  static Body = Body;
  render() {
    const { children } = this.props;
    return <div className="topic-cnt">{children}</div>;
  }
}

Topic.propTypes = {
  children: PropTypes.node
};

export { Topic };
