import * as loadImage from "blueimp-load-image";
import { EmptyFile } from "./Constants";

export const convertURIToImageData = async dataURI => {
  const isIE11 = !!(window.navigator && window.navigator.msSaveOrOpenBlob);
  if (isIE11) {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  } else {
    return await fetch(dataURI).then(r => r.blob());
  }
};

export const convertDataSrcToFile = imageSrc => {
  return convertURIToImageData(imageSrc)
    .then(function(imageData) {
      // Here you can use imageData
      const base64Split = imageSrc.split(";base64,")[0];
      const file = new Blob([imageData], {
        type: base64Split.split("data:")[1]
      });
      file.preview = imageSrc;
      return file;
    })
    .catch(err => {
      console.error("error in converting file", err);
      return EmptyFile;
    });
};

export const readAsDataURL = async (actualImage, callback) => {
  let blob = await fetch(actualImage)
    .then(r => r.blob())
    .catch(err => {
      console.error("path is not valid", err);
      callback(null);
    });

  if (!blob) return;

  let dataUrl = await new Promise(resolve => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
  callback(dataUrl);
};

export const getCroppedImg = ({ srcImage, pixelCrop, actualImage }) => {
  let img = new Image();
  img.src = actualImage;
  const targetX = (srcImage.width * pixelCrop.x) / 100;
  const targetY = (srcImage.height * pixelCrop.y) / 100;
  const targetWidth = (srcImage.width * pixelCrop.width) / 100;
  const targetHeight = (srcImage.height * pixelCrop.height) / 100;

  const canvas = document.createElement("canvas");
  canvas.width = targetWidth;
  canvas.height = targetHeight;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    img,
    targetX,
    targetY,
    targetWidth,
    targetHeight,
    0,
    0,
    targetWidth,
    targetHeight
  );

  return canvas.toDataURL("image/jpeg");
};

export const convertImageToDataURL = (actualImage, callback) => {
  let img = new Image();
  img.src = actualImage;
  img.setAttribute("crossOrigin", "anonymous");
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  img.onload = () => {
    ctx.drawImage(img, 0, 0);
    callback(canvas.toDataURL());
  };
};

export const loadImageOrientation = (accepted, callback) => {
  const loadImageOptions = {
    maxWidth: 1000,
    maxHeight: 1000,
    canvas: true
  };
  loadImage.parseMetaData(accepted, data => {
    if (data.exif && data.exif.get("Orientation")) {
      loadImageOptions.orientation = data.exif.get("Orientation");
    }
    loadImage(
      accepted,
      canvas => {
        accepted.preview = canvas.toDataURL(accepted.type);
        callback(accepted);
      },
      loadImageOptions
    );
  });
};

export const rotateImage = (actualImage, degrees, callback) => {
  let image = new Image();
  image.src = actualImage;
  image.setAttribute("crossOrigin", "anonymous");

  image.onload = () => {
    imageRotation(image, degrees, callback);
  };
};

const imageRotation = (image, degrees, callback) => {
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  canvas.style.width = "20%";

  if (degrees == 90 || degrees == 270) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  if (degrees == 90 || degrees == 270) {
    ctx.translate(image.height / 2, image.width / 2);
  } else {
    ctx.translate(image.width / 2, image.height / 2);
  }
  ctx.rotate((degrees * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);

  callback(canvas.toDataURL());
};
