import axios from "axios";
const NOTIFICATION = "/notification";
const USER_AVATAR = "/user/avatar";

export const getNotificationsApi = () => {
  return axios.get(NOTIFICATION).then(({ data }) => data);
};

export const patchNotificationsApi = id => {
  return axios.patch(`${NOTIFICATION}/${id}`).then(({ data }) => data);
};

export const postNotificationsApi = () => {
  return axios.post(NOTIFICATION).then(({ data }) => data);
};

export const fetchUserAvatar = () => {
  return axios.get(USER_AVATAR).then(({ data }) => data);
}
