const HairColors = [
  {
    id: 1,
    label: 'Auburn',
    name: 'Auburn',
  },
  {
    id: 2,
    label: 'Black',
    name: 'Black',
  },
  {
    id: 3,
    label: 'Blonde',
    name: 'Blonde',
  },
  {
    id: 4,
    label: 'Golden blonde',
    name: 'BlondeGolden',
  },
  {
    id: 5,
    label: 'Brown',
    name: 'Brown',
  },
  {
    id: 6,
    label: 'Dark brown',
    name: 'BrownDark',
  },
  {
    id: 7,
    label: 'Pastel pink',
    name: 'PastelPink',
  },
  {
    id: 8,
    label: 'Platinum',
    name: 'Platinum',
  },
  {
    id: 9,
    label: 'Red',
    name: 'Red',
  },
  {
    id: 10,
    label: 'Silver grey',
    name: 'SilverGray',
  },
];

export default HairColors;
