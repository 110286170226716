import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form
} from "reactstrap";
import "./reset-password.css";
import { Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

import { updatePasswordApi } from "./resetPasswordApi";
import { InputField } from "../InputField/InputField";
const AddUserSchema = Yup.object().shape({
  currentPassword: Yup.string().required(
    "Your current password cannot be blank"
  ),
  password: Yup.string()
    .min(4, "Your password must be longer than 4 characters")
    .max(254, "Your password has to be less than 255 characters")
    .required("Your new password cannot be blank"),
  confirmPassword: Yup.string()
    .test(
      "email-match",
      "Confirm password does not match the password above",
      function(value) {
        const { password } = this.parent;
        return password === value;
      }
    )
    .max(254, "Your confirm password has to be less than 255 characters")
    .required("Your confirm password cannot be blank")
});
class ResetPassword extends Component {
  state = {
    currentPasswordError: "",
    isFetching: false
  };
  handleSubmit = values => {
    const { currentPassword, password, confirmPassword } = values;

    const data = {
      oldPassword: window.btoa(currentPassword),
      newPassword: window.btoa(password),
      confirmNewPassword: window.btoa(confirmPassword)
    };
    this.setState({ isFetching: true });
    updatePasswordApi(data)
      .then(resp => {
        Swal({
          type: "success",
          text: "Your password has been reset!",
          timer: 5000
        }).then(() => {
          this.handleClose();
        });
      })
      .catch(({ response }) => {
        const { message, errorMessage } = response.data;

        message &&
          this.setState({
            currentPasswordError:
              message.toLowerCase().indexOf("current password") >= 0 && message
          });
        errorMessage &&
          Swal({
            type: "error",
            title: "Oops...",
            text:
              "Whoops, something went wrong! Please contact the administrator.",
            timer: 5000
          });
        this.setState({ isFetching: false });
      });
  };

  handleClose = () => {
    this.setState({
      currentPasswordError: "",
      isFetching: false
    });
    this.props.onClose();
  };

  render() {
    const { passwordModal } = this.props;
    const { isFetching, currentPasswordError } = this.state;

    return (
      <Modal
        isOpen={passwordModal}
        className="modal-popup reset-password-modal"
        centered
        toggle={this.handleClose}
      >
        <ModalHeader toggle={this.handleClose} />
        <ModalBody className="user-modal-body">
          <h3 className="title-change-password">Reset Password</h3>
          <FormGroup className="register-form">
            <label className="sub-title ">
              Fill in the information below to reset your password
            </label>
            <br />
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={AddUserSchema}
              render={props => (
                <UserForm
                  {...props}
                  currentPasswordError={currentPasswordError}
                  isFetching={isFetching}
                  handleChange={e => {
                    this.setState({ currentPasswordError: "" });
                    props.handleChange(e);
                  }}
                />
              )}
              validateOnBlur={false}
              validateOnChange={false}
            />
          </FormGroup>
        </ModalBody>
      </Modal>
    );
  }
}

const UserForm = props => {
  const {
    handleSubmit,
    handleChange,
    errors,
    currentPasswordError,
    isFetching,
    values
  } = props;
  const { currentPassword, password, confirmPassword } = values;
  return (
    <Form className="" onSubmit={handleSubmit}>
      <InputField
        placeholder="Enter current password..."
        viewPassword
        type="password"
        name="currentPassword"
        value={currentPassword}
        invalid={!!errors.currentPassword || !!currentPasswordError}
        errorMessage={errors.currentPassword || currentPasswordError}
        onChange={handleChange}
      />

      <InputField
        placeholder="Enter new password..."
        viewPassword
        type="password"
        name="password"
        value={password}
        invalid={!!errors.password}
        errorMessage={errors.password}
        onChange={handleChange}
      />

      <InputField
        placeholder="Confirm new password..."
        viewPassword
        type="password"
        name="confirmPassword"
        value={confirmPassword}
        invalid={!!errors.confirmPassword}
        errorMessage={errors.confirmPassword}
        onChange={handleChange}
      />
      <br />

      <Button disabled={isFetching} className="submit-btn w-100" type="submit">
        Submit
      </Button>
    </Form>
  );
};
export default ResetPassword;
