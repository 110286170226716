const FacialHairColors = [
  {
    id: 1,
    label: 'Auburn',
    name: 'Auburn',
  },
  {
    id: 2,
    label: 'Black',
    name: 'Black',
  },
  {
    id: 3,
    label: 'Blonde',
    name: 'Blonde',
  },
  {
    id: 4,
    label: 'Golden blonde',
    name: 'BlondeGolden',
  },
  {
    id: 5,
    label: 'Brown',
    name: 'Brown',
  },
  {
    id: 6,
    label: 'Dark brown',
    name: 'BrownDark',
  },
  {
    id: 7,
    label: 'Platinum',
    name: 'Platinum',
  },
  {
    id: 8,
    label: 'Red',
    name: 'Red',
  },
];

export default FacialHairColors;
