import { Back } from "../../../assets/images";
import React from "react";
import "./backbutton.css";

const BackButton = ({ handleClick }) => (
  <div className="back-btn">
    <img className="back-btn-tri" src={Back} onClick={handleClick} alt="back" />
  </div>
);

export { BackButton };
