import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import "../ModalPopup/modal.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./notification.css";
import { withRouter } from "react-router-dom";
import { trackNotificationClick } from "../../services/activityLogs";
import { ProfileIcon } from "../Header/ProfileIcon/ProfileIcon";
const NotificationList = ({ children }) => (
  <ul className="notification-list">{children}</ul>
);
const Item = ({ children, active, clickable, className, onClick }) => (
  <li
    onClick={onClick}
    className={`notification-item ${active ? "active" : ""} ${
      clickable ? "pointer" : ""
    } ${className}`}
  >
    {children}
  </li>
);
const Content = ({ children }) => (
  <div className="notification-content">{children}</div>
);
const Image = ({ type }) => (
  <div className={`notification-image-wrap ${type}`} />
);
Image.defaultProps = {
  type: "",
  className: ""
};
const Types = {
  TEACHER_FEEDBACK: "teacherFeedback",
  STORY_PUBLISHED: "storyPublished",
  LAUGH: "laugh",
  LOVE: "love",
  SHOCK: "shock",
  CRY: "cry",
  HAPPY: "happy",
  Empty: "none",
  CLASSMATE_PUBLISHED_STORY: "classmateStoryPublished",
  PEOPLE_READING_STORY: "peopleReadingWork"
};

class Notification extends Component {
  state = {
    showPopupNotification: false
  };

  handleNavigateToStory = info => {
    if (info.showStoryError) {
      this.setState({ showPopupNotification: true });
      return;
    }
    const { history } = this.props;
    this.props.onClose();
    const { description, storyId } = info;

    trackNotificationClick({ result: `${description}!`, storyId });
    if (
      info.notificationTitle === "CLASSMATE_PUBLISHED_STORY" ||
      info.notificationTitle === "TEACHER_APPROVES_PERSONAL_FEEDBACK" ||
      info.notificationTitle === "TEACHER_DECLINES_PERSONAL_FEEDBACK"
    ) {
      const storyId = window.btoa(info.storyId);
      history.push(`/classmate-work/${storyId}`, this.forceUpdate);
    } else {
      const storyId = window.btoa(info.storyId);
      history.push(
        {
          pathname: `/story-work/${storyId}`,
          search: "?query=workspace"
        },
        this.forceUpdate
      );

      // history.push(`/workspace/${info.storyId}`);
    }
  };
  getAvatarJson = avatar => {
    if (!avatar) return null;
    delete avatar.avatarId;
    const newAvatar = {
      ...avatar,
      style: {
        width: "62px",
        height: "62px"
      }
    };
    return newAvatar;
  };
  handleNavigateToProfile = info => {
    const { history } = this.props;
    this.props.onClose();
    history.push(`/profile/${info.otherUserId}`);
  };

  togglePopupNotification = () => {
    this.setState({
      showPopupNotification: false
    });
    this.props.onClose();
  };

  render() {
    const { onClose, notifications, modelColor } = this.props;
    const { showPopupNotification } = this.state;
    return (
      <div className="notification">
        {showPopupNotification ? (
          <Modal
            className="modal-popup"
            isOpen={true}
            centered={true}
            toggle={this.togglePopupNotification}
          >
            <div style={{ backgroundColor: modelColor }}>
              <ModalHeader
                toggle={this.togglePopupNotification}
                style={{
                  marginBottom: "0px"
                }}
              />
              <ModalBody
                style={{
                  marginTop: "0px",
                  paddingTop: "0px"
                }}
              >
                <h3
                  style={{
                    paddingTop: "0px"
                  }}
                >
                  Sorry!
                </h3>
                <p>This story has been deleted.</p>
              </ModalBody>
            </div>
          </Modal>
        ) : (
          ""
        )}
        <div className="notification-overlay" onClick={onClose} />
        <PerfectScrollbar className="notification-wrapper animated fadeInRight faster">
          <Button onClick={onClose} className="close-btn" />
          <h2>Your Notifications</h2>
          <NotificationList>
            {notifications.length > 0 ? (
              notifications.map(item => (
                <Item
                  className="animated zoomIn faster"
                  clickable={item.clickable}
                  active={!item.read}
                  key={item.id}
                  onClick={() => {
                    item.clickable && this.handleNavigateToStory(item);
                  }}
                >
                  {item.notificationTitle ===
                  "NEW_PERSONAL_FEEDBACK_ON_STORY" ? (
                    <Fragment>
                      <ProfileIcon
                        onClick={() => this.handleNavigateToProfile(item)}
                        avatar={this.getAvatarJson(item.avatar)}
                      />
                      <Content>
                        <b
                          className="pointer"
                          onClick={() => this.handleNavigateToProfile(item)}
                        >
                          {item.firstName}
                          &nbsp;
                          {item.lastName}
                          &nbsp;
                        </b>
                        {item.description}
                        ,&nbsp;
                        <b
                          className="pointer"
                          onClick={() => this.handleNavigateToStory(item)}
                        >
                          {item.storyTitle}
                        </b>
                      </Content>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Image type={Types[item.emoji] || "teacherFeedback"} />
                      <Content>
                        {item.description}
                        ,&nbsp;
                        <b>{item.storyTitle}</b>!
                      </Content>
                    </Fragment>
                  )}
                </Item>
              ))
            ) : (
              <div className="no-notification">
                There's nothing here at the moment, check back later!
              </div>
            )}
          </NotificationList>
        </PerfectScrollbar>
      </div>
    );
  }
}
export default withRouter(Notification);
