import axios from "axios";

const AUTH_END_POINT = "/auth";
const TOKENLOGIN = token => `/link/activate?token=${token}`;
const TOKENFORGOTPASS = `/user/forgot-password`;
const TOKENVALIDATE = token => `/forgot-password/${token}`;
const RESETPASSWORD = token => `/forgot-password/validate/${token}`;
const VALIDATERESETPASSWORD = token => `/reset-password/${token}`;

export const authenticate = ({ username, password }) => {
  return axios
    .post(AUTH_END_POINT, {
      username,
      password
    })
    .then(({ data }) => data.data);
};

export const tokenLoginApi = token => {
  return axios.get(TOKENLOGIN(token)).then(({ data }) => {
    return data;
  });
};

export const forgotPasswordApi = (userEmail, message) => {
  return axios
    .post(TOKENFORGOTPASS, { email: userEmail, message: message })
    .then(({ data }) => {
      return data;
    });
};
export const validateLinkApi = token => {
  return axios.get(TOKENLOGIN(token)).then(({ data }) => {
    return data;
  });
};

export const validateReset = token => {
  return axios.get(TOKENVALIDATE(token)).then(({ data }) => {
    return data;
  });
};
export const resetPasswordApi = token => {
  return axios.get(RESETPASSWORD(token)).then(({ data }) => {
    return data;
  });
};

export const resetPasswordValidateApi = (token, password, confirmPassword) => {
  return axios
    .post(VALIDATERESETPASSWORD(token), {
      password: password,
      confirmPassword: confirmPassword
    })
    .then(({ data }) => {
      return data;
    });
};
