import React, { Component } from "react";
import { Button, Form } from "reactstrap";
import { withRouter, Redirect } from "react-router-dom";
import { authenticate } from "../accountActions";
import { PageCenter, InputField } from "../../../common";
import { connect } from "react-redux";
import "./login.css";
import { messages, USER_ROLES } from "../../../utils";
import { logOnClick } from "../../../services/activityLogs";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import split from "lodash/split";
import { tokenLoginApi } from "../accountApi";
import queryString from "query-string";
import Swal from "sweetalert2";
import { RouteNames } from "../../../Routes/RouteNames";
import withReactContent from "sweetalert2-react-content";
import ccLogo from "../../../assets/images/logo_org_md.svg";
import iosLogo from "../../../assets/images/ios.png";
import android from "../../../assets/images/android.png";
const MySwal = withReactContent(Swal);

class Login extends Component {
  state = {
    username: "",
    password: "",
    errorMessageUsername: "",
    errorMessagePassword: "",
    redirectToWelcome: false,
    redirectToStudentDashboard: false,
    errorMessage: "",
    isFetching: false,
    redirectTeacher: false,
    redirectModerator: false,
    redirectAdmin: false,
  };

  contains = (arr, role) => {
    return arr.filter((item) => item === role).length > 0;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    username
      ? this.setState({ errorMessageUsername: "" })
      : this.setState({
          errorMessageUsername: messages.validation.requiredField,
          errorMessage: "",
        });
    password
      ? this.setState({ errorMessagePassword: "" })
      : this.setState({
          errorMessagePassword: messages.validation.requiredField,
          errorMessage: "",
        });
    if (username && password) {
      this.setState({
        isFetching: true,
        errorMessagePassword: "",
        errorMessageUsername: "",
        errorMessage: "",
      });

      const { authenticate } = this.props;
      authenticate({ username, password })
        .then((data) => {
          logOnClick({ result: "Login successfully" });
          const hasAvatar = !some(data.avatar, isEmpty);
          if (this.contains(data.roles, USER_ROLES.TEACHER)) {
            this.setState({
              isFetching: false,
              redirectTeacher: hasAvatar,
              redirectToWelcome: !hasAvatar,
            });
          }
          if (this.contains(data.roles, USER_ROLES.MODERATOR)) {
            this.setState({
              isFetching: false,
              redirectModerator: !hasAvatar,
              redirectToWelcome: hasAvatar,
              redirectTeacher: hasAvatar,
            });
          } else if (this.contains(data.roles, USER_ROLES.ADMIN)) {
            this.setState({ redirectAdmin: true, isFetching: false });
          } else {
            this.setState({
              redirectToWelcome: !hasAvatar,
              redirectToStudentDashboard: hasAvatar,
              isFetching: false,
            });
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            const { message } = error.response.data;
            const splitMessage = split(message, "!", 2);
            const [titleRaw, textRaw] = splitMessage;
            const title = titleRaw.concat("!");
            const text = textRaw.concat("!");

            logOnClick({ result: "Login failed with username " + username });
            this.setState({
              isFetching: false,
            });

            MySwal.fire({
              title,
              text,
              type: "error",
              showConfirmButton: true,
              timer: 10000,
            });
          } else {
            MySwal.fire({
              title: "Oops..",
              text:
                "Internal server error! Please contact support@connectedcreatives.co.uk",
              type: "error",
              showConfirmButton: true,
              timer: 10000,
            });
          }
        });
    }
  };

  handleUsernameChange = (event) => {
    this.setState({
      username: event.target.value,
    });
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };
  componentDidMount() {
    const value = queryString.parse(this.props.location.search);
    this.setState({
      token: value.token,
    });
    value.token &&
      tokenLoginApi(encodeURIComponent(value.token))
        .then((response) => {
          MySwal.fire({
            type: "success",
            title: "You're all set!",
            text: "Log in to get started creating!",
            showConfirmButton: false,

            timer: 4000,
          });
          this.props.history.push(RouteNames.Login.route);
        })
        .catch(({ response }) => {
          MySwal.fire({
            type: "error",
            title:
              "The link is not valid, Please contact your teacher. Thanks!",
            showConfirmButton: false,
            timer: 4000,
          });
        });
  }
  render() {
    const {
      username,
      password,
      errorMessageUsername,
      errorMessagePassword,
      errorMessage,
      isFetching,
      redirectToWelcome,
      redirectTeacher,
      redirectModerator,
      redirectAdmin,
      redirectToStudentDashboard,
    } = this.state;

    if (redirectToWelcome) {
      return <Redirect to="/welcome" />;
    }
    if (redirectToStudentDashboard) {
      return <Redirect to="/student-dashboard" />;
    }

    if (redirectTeacher) {
      return <Redirect to="/teacher-dashboard" />;
    }
    if (redirectModerator) {
      return <Redirect to="/moderator-group" />;
    }

    if (redirectAdmin) return <Redirect to="/admin" />;

    return (
      <PageCenter className="login">
        <Form className="login-form">
          <div className="login-logo-wrapper">
            <img src={ccLogo} alt="login-logo" className="login-logo" />
          </div>
          <label className="error-message">{errorMessage}</label>

          <InputField
            label="Email/Username"
            type="text"
            name="username"
            value={username}
            onChange={this.handleUsernameChange}
            invalid={!!errorMessageUsername}
            errorMessage={errorMessageUsername}
          />

          <InputField
            label="Password"
            type="password"
            viewPassword
            name="password"
            value={password}
            onChange={this.handlePasswordChange}
            invalid={!!errorMessagePassword}
            errorMessage={errorMessagePassword}
          />
          <a
            className="forgotPassCls"
            onClick={() =>
              this.props.history.push(RouteNames.ForgotPassword.route)
            }
          >
            Forgot Password
          </a>
          <Button
            disabled={isFetching}
            className="round-button w-100 loginBtn"
            onClick={this.handleSubmit}
            type="submit"
          >
            Jump In
          </Button>

          <Button
            className="round-button w-100 loginBtn"
            onClick={() =>
              this.props.history.push(RouteNames.SchoolRegister.route)
            }
            type="button"
          >
            Register
          </Button>
          <div className="login-logo-wrapper">
            <a
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.connectedcreativesapp"
              target="_blank"
            >
              <img src={android} alt="ios-logo" className="login-logo" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://apps.apple.com/gb/app/connected-creatives/id1513131577"
              target="_blank"
            >
              <img src={iosLogo} alt="ios-logo" className="login-logo" />
            </a>
          </div>
        </Form>
      </PageCenter>
    );
  }
}

export default connect(null, { authenticate })(withRouter(Login));
