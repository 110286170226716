import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Avatar from "avataaars";
import "../ModalPopup/modal.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./side-nav.css";
import {
  LibraryOff,
  NotificationOff,
  SettingsOff,
  ClassesOff,
  ClassesOn,
  LibraryOn,
  SettingsOn,
  CompOff,
  CompOn,
  ClassIconOn,
  ClassIcon
} from "../../assets/images";
import { RouteNames } from "../../Routes/RouteNames";
import { logout } from "../../common/LogoutButton/logoutApi";

const SideNavProfileItem = ({ name, avatar, onAvatarClick, themeColor }) => (
  <div
    className="side-nav-profile-item"
    style={{ backgroundColor: themeColor }}
  >
    <span className="avatar-wrapper" onClick={onAvatarClick}>
      <Avatar
        className="avatar-image"
        avatarStyle="Circle"
        style={{ width: "100px", height: "100px" }}
        {...avatar}
      />
    </span>
    <span className="user-name">{name}</span>
  </div>
);

const Item = ({ children, className, onClick }) => (
  <li onClick={onClick} className={`side-nav-item ${className}`}>
    {children}
  </li>
);

class SideNav extends Component {
  state = {
    active: false
  };

  handleCompetitionClick = () => {
    this.props.history.push("/competition-dashboard");
  };

  onDashClick = () => {
    const { isTeacher, history } = this.props;

    history.push(
      isTeacher
        ? RouteNames.TeacherDashboard.route
        : RouteNames.StudentDashboard.route
    );
  };

  render() {
    const {
      firstName,
      onNotificationClick,
      history,
      avatar,
      onClose,
      onResetPassword,
      onLogout,
      onProfileChange,
      themeColor,
      view,
      isActiveCompetition,
      isTeacher
    } = this.props;
    return (
      <div className="side-nav">
        <div className="side-nav-overlay" onClick={onClose} />
        <PerfectScrollbar className="side-nav-wrapper fadeInRight faster">
          <SideNavProfileItem
            name={firstName}
            avatar={avatar}
            onAvatarClick={onProfileChange}
            themeColor={themeColor}
          />
          <ul className="side-nav-list">
            {/* <Item onClick={() => {}}>
              <img className="item-img" src={SearchOff} />
              <div className="item-content">Search</div>
            </Item> */}
            <Item onClick={this.onDashClick}>
              <img
                className="item-img"
                src={
                  view == "dashboard" || view == "teacher-dashboard"
                    ? ClassesOn
                    : ClassesOff
                }
              />
              <div className="item-content">Dashbord</div>
            </Item>

            {isTeacher && (
              <Item
                onClick={() =>
                  history.push(RouteNames.TeacherGroupDashboard.route)
                }
              >
                <img
                  className="item-img"
                  src={view == "teacher-group" ? ClassIconOn : ClassIcon}
                />
                <div className="item-content">Classes</div>
              </Item>
            )}

            <Item
              onClick={() =>
                history.push(
                  isTeacher
                    ? {
                        pathname: `/teacher-library`,
                        search: "?query=tech"
                      }
                    : RouteNames.StudentLibrary.route
                )
              }
            >
              <img
                className="item-img"
                src={view == "library" ? LibraryOn : LibraryOff}
              />
              <div className="item-content">Library</div>
            </Item>
            {/* <Item onClick={() => {}}>
              <img className="item-img" src={CompOff} />
              <div className="item-content">Competitions</div>
            </Item> */}
            {!isTeacher && (
              <Item
                onClick={() => {
                  onNotificationClick();
                  onClose();
                }}
              >
                <img className="item-img" src={NotificationOff} />
                <div className="item-content">Notification</div>
              </Item>
            )}

            {isActiveCompetition && (
              <Item onClick={this.handleCompetitionClick}>
                <img
                  className="item-img"
                  src={view == "competition" ? CompOn : CompOff}
                />
                <div className="item-content">Competition</div>
              </Item>
            )}

            <Item onClick={() => this.setState({ active: !this.state.active })}>
              <img
                className="item-img"
                src={this.state.active ? SettingsOn : SettingsOff}
              />
              <div className="item-content">Settings</div>
            </Item>

            {this.state.active && (
              <div>
                <Item className="side-nav-sub-item" onClick={onResetPassword}>
                  <div className="item-content">Reset Password</div>
                </Item>
                <Item className="side-nav-sub-item" onClick={onLogout}>
                  <div className="item-content">Logout</div>
                </Item>
              </div>
            )}
          </ul>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default connect(
  ({ user: { firstName } }) => ({ firstName }),
  { onLogout: logout }
)(withRouter(SideNav));
