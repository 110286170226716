import React, { Component } from "react";
import Avatar from "avataaars";
import find from "lodash/find";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import AvatarForm from "./AvatarForm/AvatarForm";
import { saveAvatarAction } from "../../views/commonView/Welcome/welcomeApi";
import {
  Top,
  Accessories,
  HairColors,
  FacialHairTypes,
  FacialHairColors,
  ClotheTypes,
  EyeTypes,
  EyebrowTypes,
  MouthTypes,
  SkinTypes,
  ClotheColors
} from "./AvatarForm/Options";
class AvatarWrapper extends Component {
  state = {
    isLoading: false,
    saveBtnDisabled: false,
    notNowBtnDisabled: false,
    userAvatar: {
      topType: "NoHair",
      accessoriesType: "Blank",
      hairColor: "Brown",
      facialHairType: "Blank",
      facialHairColor: "Blank",
      clotheType: "BlazerShirt",
      eyeType: "Default",
      eyebrowType: "Default",
      mouthType: "Default",
      skinColor: "Light",
      clotheColor: "Gray01"
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userAvatar !== this.props.userAvatar) {
      this.setState({ userAvatar: this.props.userAvatar });
    }
  }
  redirectToDashboard = () => {
    this.props.history.push("/");
  };

  onChange = e => {
    const userAvatar = {
      ...this.state.userAvatar,
      [e.target.name]: e.target.value
    };

    this.setState({ userAvatar });
  };

  renderAccessories = () => {
    const { topType } = this.state.userAvatar;
    return topType !== "Eyepatch";
  };

  renderHaircolor = () => {
    const { topType } = this.state.userAvatar;
    const obj = find(Top, { name: topType });
    return obj.type === "HAIR";
  };

  renderFacialHairColour = () => {
    const { facialHairType } = this.state.userAvatar;
    return facialHairType !== "Blank";
  };

  renderClothesFabric = () => {
    const { clotheType } = this.state.userAvatar;
    const obj = find(ClotheTypes, { name: clotheType });
    return obj.type !== "BLAZER";
  };

  generateRandomAvatar = () => {
    const userAvatar = {
      topType: Top[Math.floor(Math.random() * Top.length)].name,
      accessoriesType:
        Accessories[Math.floor(Math.random() * Accessories.length)].name,
      hairColor: HairColors[Math.floor(Math.random() * HairColors.length)].name,
      facialHairType:
        FacialHairTypes[Math.floor(Math.random() * FacialHairTypes.length)]
          .name,
      facialHairColor:
        FacialHairColors[Math.floor(Math.random() * FacialHairColors.length)]
          .name,
      clotheType:
        ClotheTypes[Math.floor(Math.random() * ClotheTypes.length)].name,
      eyeType: EyeTypes[Math.floor(Math.random() * EyeTypes.length)].name,
      eyebrowType:
        EyebrowTypes[Math.floor(Math.random() * EyebrowTypes.length)].name,
      mouthType: MouthTypes[Math.floor(Math.random() * MouthTypes.length)].name,
      skinColor: SkinTypes[Math.floor(Math.random() * SkinTypes.length)].name,
      clotheColor:
        ClotheColors[Math.floor(Math.random() * ClotheColors.length)].name
    };

    this.setState({ userAvatar });
  };

  saveAvatarClicked = () => {
    const { userAvatar } = this.state;
    this.setState({ notNowBtnDisabled: true });

    this.props.saveAvatarAction(userAvatar).then(resp => {
      this.setState({ notNowBtnDisabled: false }, () => {
        this.props.history.push("/");
      });
    });
  };

  render() {
    const { saveBtnDisabled, notNowBtnDisabled, userAvatar } = this.state;
    const { heading, detail, saveButton } = this.props;
    return (
      <div>
        <div className="wrapper">
          <h2>{heading}</h2>
          <p>{detail}</p>
        </div>
        <div className="upload-container">
          <div className="avatar-cnt">
            <div className="avatar-image-cnt">
              <Avatar
                className="avatar-image"
                avatarStyle="Circle"
                {...userAvatar}
              />
            </div>
          </div>

          <div className="random-button-cnt">
            <Button
              className="round-button random-button"
              onClick={this.generateRandomAvatar}
            >
              Random
            </Button>
          </div>

          <div className="avatar-form-cnt">
            <AvatarForm
              onChange={this.onChange}
              renderAccessories={this.renderAccessories()}
              renderHairColor={this.renderHaircolor()}
              renderFacialHairColour={this.renderFacialHairColour()}
              renderClothesFabric={this.renderClothesFabric()}
              {...userAvatar}
            />

            <div className="action-btn-cnt">
              <Button
                onClick={this.redirectToDashboard}
                className="round-button white-button float-left outlined"
                disabled={notNowBtnDisabled}
              >
                Not right now
              </Button>

              <Button
                onClick={this.saveAvatarClicked}
                className="round-button float-right"
                disabled={saveBtnDisabled}
              >
                {saveButton}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user
  };
};

export default connect(
  mapStateToProps,
  { saveAvatarAction }
)(withRouter(AvatarWrapper));
