import React, { Component, Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import classNames from "classnames";
import take from "lodash/take";
import takeRight from "lodash/takeRight";
import "./storyreading.css";

const Title = ({ children }) => <h3>{children}</h3>;
const Blurb = ({ children }) => <h4 className="blurb-title">{children}</h4>;
const Heading = ({ children }) => (
  <div className="story-heading">{children}</div>
);
const Genres = ({ children }) => <div className="story-genres">{children}</div>;
const SubTitle = ({ children }) => (
  <div className="story-sub-title">{children}</div>
);
const Body = ({ children }) => <div className="body"> {children}</div>;
const Detail = ({ children }) => (
  <PerfectScrollbar className="story-detail">{children}</PerfectScrollbar>
);
const ProfileName = ({ children, onClick }) => (
  <div className="profile-name" onClick={onClick}>
    {children}
  </div>
);

const Genre = ({ genre }) => {
  return (
    <li className="story-title-genre">
      <img
        className="title-genre-icon"
        alt={genre.name}
        src={require(`../../assets/images/genreicons/hover/${genre.path}`)}
      />
    </li>
  );
};

export class StoryTitleGenres extends Component {
  state = {
    displayAllGenres: false
  };
  render() {
    const { genres, className } = this.props;
    const { displayAllGenres } = this.state;
    const viewingGenres = take(genres, 3);
    const hiddenGenres = takeRight(genres, genres.length - 3);
    return (
      <div
        className={classNames(
          "story-title-genres-list",
          className,
          displayAllGenres && "display-all-genres"
        )}
      >
        <ul className="genres-displaying">
          {viewingGenres.map(genre => (
            <Genre key={genre.id} genre={genre} />
          ))}
          {hiddenGenres && hiddenGenres.length > 0 && (
            <li
              onMouseEnter={() => this.setState({ displayAllGenres: true })}
              onMouseLeave={() => this.setState({ displayAllGenres: false })}
              className="remaning-genres"
            >
              +{genres.length - 3}
            </li>
          )}
        </ul>
        {hiddenGenres && hiddenGenres.length > 0 && (
          <Fragment>
            <span className="up-arrow" />
            <ul className="genres-hidden">
              {hiddenGenres.map(genre => (
                <Genre key={genre.id} genre={genre} />
              ))}
            </ul>
          </Fragment>
        )}
      </div>
    );
  }
}

class StoryReading extends Component {
  static Title = Title;
  static Blurb = Blurb;
  static Heading = Heading;
  static Genres = Genres;
  static SubTitle = SubTitle;
  static Body = Body;
  static Detail = Detail;
  static ProfileName = ProfileName;
  render() {
    const { children } = this.props;
    return <div className="story-reading">{children}</div>;
  }
}

export { StoryReading };
